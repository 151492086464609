import React, {Component} from 'react';
import Dialog, {DialogButton, DialogContent, DialogFooter, DialogTitle} from "@material/react-dialog";
import TextField, {Input} from "@material/react-text-field";
import Spinner from "./Spinner";
import {functions} from "../firebase";

class PhoneCodeDialog extends Component {
    state = {
        codeSent: false,
        codeSentError: false,
    };

    render() {
        const {codeSent, codeSentError} = this.state

        return (
            <Dialog
                onClose={this._onClose.bind(this)}
                open={this.props.open}>
                <DialogTitle>Phone Verification</DialogTitle>
                <DialogContent>
                    {codeSentError ? (<span>There was an error sending SMS</span>) : (
                        codeSent ? (<TextField label='6-digit code'>
                            <Input value={this.state.value}
                                   onChange={(e) => this.setState({value: e.currentTarget.value})}/>
                        </TextField>) : (<Spinner/>)
                    )}
                </DialogContent>
                <DialogFooter>
                    <DialogButton action='dismiss'>Cancel</DialogButton>
                    <DialogButton action='accept' disabled={!codeSent}>Confirm</DialogButton>
                </DialogFooter>
            </Dialog>
        );
    }

    _onClose(action) {
        if (this.props.onClose) this.props.onClose(action, this.state.value, this.state.verificationId)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.open !== this.props.open) {
            if (this.props.open) {
                const sendPhoneNumberVerification = functions.httpsCallable("sendPhoneNumberVerification")
                sendPhoneNumberVerification().then(res => {
                    if (res.data) {
                        this.setState({codeSent: true, verificationId: res.data.Id})
                    } else {
                        this.setState({codeSentError: true})
                    }
                }).catch(err => {
                    console.error(err)
                    this.setState({codeSentError: true})
                })
            } else {
                this.setState({
                    codeSent: false,
                    codeSentError: false
                })
            }
        }
    }
}

export default PhoneCodeDialog;
