import {GET_INVOICE_ISSUERS} from '../actions/invoiceIssuers';

const INITIAL_STATE = {
    ordered: [],
    filterByCustomer: null, // filter
    limit: 20,
    loadMoreExhausted: false,
    updated: null,
    processing: false,
};

const invoiceIssuers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_INVOICE_ISSUERS:
            return {
                ...state,
                ...action.dataMap,
                ordered: action.replace ? [...(action.ordered || [])] : [...state.ordered, ...(action.ordered || [])],
                loadMoreExhausted: action.loadMoreExhausted,
                updated: action.updated,
            };
        default:
            return state;
    }
};

export default invoiceIssuers;
