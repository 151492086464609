import {CLOSE_DIALOG, OPEN_DIALOG} from '../actions/dialog';

const INITIAL_STATE = {
    dialog: '',
    onAction: null,
};

const dialog = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPEN_DIALOG:
            return {
                ...state,
                dialog: action.dialog,
                onAction: action.onAction,
            };
        case CLOSE_DIALOG:
            return {
                ...state,
                dialog: '',
                onAction: null
            };
        default:
            return state;
    }
};
export default dialog;