import {AUTHENTICATE_USER, UPDATE_USER, UPDATE_USER_2FA} from '../actions/user';
import {SESSION_EXPIRED} from "../authMiddleware";

const INITIAL_STATE = {
    currentUser: false,
    authenticationHeader: localStorage.getItem("_uidssau") || null,
    uid: localStorage.getItem("_uidssid") || null,
    authResponse: null,
    redirectOnLoginUri: "",
    error: ''
};

const user = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTHENTICATE_USER:
            localStorage.setItem("_uidssau", action.authenticationHeader || "");
            localStorage.setItem("_uidssid", action.user ? action.user.Id : "");
            return {
                ...state,
                authenticationHeader: action.authenticationHeader,
                authResponse: action.authResponse,
                currentUser: action.user,
                uid: action.user ? action.user.Id : "",
                redirectOnLoginUri: action.redirectOnLoginUri || "",
                error: ''
            };
        case UPDATE_USER:
            return {
                ...state,
                currentUser: action.user,
                error: ''
            };
        case UPDATE_USER_2FA:
            return {
                ...state,
                currentUser: _user(state.currentUser, action),
            };
        case SESSION_EXPIRED:
            localStorage.setItem("_uidssau", "");
            localStorage.setItem("_uidssid", "");
            return {
                ...state,
                currentUser: null,
                authenticationHeader: null,
                uid: null,
                authResponse: null,
                redirectOnLoginUri: action.redirectOnLoginUri || "",
                error: 'sessionExpired'
            };
        default:
            return state;
    }
};

const _user = (state, action) => {
    switch (action.type) {
        case UPDATE_USER_2FA:
            return {
                ...state,
                ["status"]: {...state["status"], twoFactorAuthEnabled: action.twoFactorAuthEnabled}
            };
        default:
            return state
    }
};

export default user;
