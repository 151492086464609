import firebase, {firestore, functions} from '../firebase';

export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const GET_ORGANIZATION_IBAN_LIST = 'GET_ORGANIZATION_IBAN_LIST';
export const GET_USERS_ACCESS = 'GET_USERS_ACCESS';

export const QUERY_ORGANIZATIONS = 'QUERY_ORGANIZATIONS';

export const getOrganization = (organizationId, cb = () => {}) => (dispatch, getState) => {
    firestore.collection("organizations").doc(organizationId).get().then(doc => {
        dispatch({
            type: GET_ORGANIZATION,
            id: doc.id,
            data: {...doc.data(), id: doc.id, _doc: doc}
        })
        cb()
    }).catch(err => {
        console.error(err)
        cb()
    })
};

export const getCurrentOrganization = () => (dispatch, getState) => {
    const state = getState(), organizationId = state.organizations.active
    firestore.collection("organizations").doc(organizationId).get().then(doc => {
        dispatch({
            type: GET_ORGANIZATION,
            id: doc.id,
            data: {...doc.data(), id: doc.id, _doc: doc}
        })
    }).catch(err => {
        console.error(err)
    })
};

export const getOrganizationIBANList = (cb) => (dispatch, getState) => {
    const state = getState(), organizationId = state.organizations.active
    const getIBANlistForOrganization = functions.httpsCallable("getIBANlistForOrganization")
    getIBANlistForOrganization({
        organizationId
    }).then(res => {
        if (cb) cb(res.data)
    }).catch(err => {
        console.error(err)
        if (cb) cb(null, err)
    })
    // TODO: save to state
}

export const updateOrganizationIBAN = (iban, code, verificationId, cb) => (dispatch, getState) => {
    const state = getState(), organizationId = state.organizations.active

    const updateBankAccountIBAN = functions.httpsCallable("updateBankAccountIBAN")
    updateBankAccountIBAN({
        organizationId, iban, code, verificationId
    }).then(res => {
        const data = res.data;
        console.log(res)
        if (data) {
            console.log(data)
        }
        if (cb) cb(data)
    }).catch(err => {
        console.error(err)
        if (cb) cb(null, err)
    })
}

export const getOrganizationUsers = () => (dispatch, getState) => {
    const state = getState(), organizationId = state.organizations.active
    const getUsersWithAccessToOrganization = functions.httpsCallable("getUsersWithAccessToOrganization")
    getUsersWithAccessToOrganization({
        organizationId
    }).then(res => {
        /*if (res && res.data) {
            dispatch({
                type: GET_USERS_ACCESS,
                data: res.data
            })
        }*/
        dispatch({
            type: GET_USERS_ACCESS,
            data: res.data
        })
    }).catch(err => {
        dispatch({
            type: GET_USERS_ACCESS,
            data: null,
            userAccessError: err
        })
    })
}

export const updateOrganizationUserRole = (userId, role, removeAccess = false, cb) => (dispatch, getState) => {
    const state = getState(), organizationId = state.organizations.active
    const updateUserOrganizationRole = functions.httpsCallable("updateUserOrganizationRole")
    updateUserOrganizationRole({
        organizationId, userId, role, removeAccess
    }).then(res => {
        if (res && res.data) {
            if (cb) cb(res.data)
        } else if (cb) cb()
        dispatch(getOrganizationUsers())
    }).catch(err => {
        console.error(err)
        if (cb) cb(null, err)
    })
}


export const inviteMemberToOrganization = (email, cb) => (dispatch, getState) => {
    const state = getState(), organizationId = state.organizations.active
    const sendUserOrganizationInvitation = functions.httpsCallable("sendUserOrganizationInvitation")
    sendUserOrganizationInvitation({
        organizationId, email
    }).then(res => {
        if (res && res.data) {
            if (cb) cb(res.data)
        } else if (cb) cb()
    }).catch(err => {
        console.error(err)
        if (cb) cb(null, err)
    })
}


/*
ADMIN
 */

export const queryOrganizations = ({
                                       orderBy = "updatedAt",
                                       orderByDirection = "desc"
                                   } = {}, loadMore = false, replace = false) => (dispatch, getState) => {
    const state = getState(), limit = state.organizations.limit, ordered = state.organizations.ordered,
        updated = (new Date()).getTime()

    if (ordered.length > 0 && !loadMore && !replace) return

    let query = firestore.collection("organizations").orderBy(orderBy, orderByDirection).limit(limit)

    if (loadMore && ordered.length > 0) query = query.startAfter(ordered[ordered.length - 1]._doc)

    query.get().then(snap => {
        const ordered = [];
        snap.forEach(doc => ordered.push({...doc.data(), id: doc.id, _doc: doc, displayActions: true, action: "view"}))
        dispatch({
            type: QUERY_ORGANIZATIONS,
            ordered, loadMoreExhausted: (snap.empty || snap.size < limit), updated, replace
        })
    }).catch(err => {
        console.error(err)
    })
};

export const confirmOrganizationIBAN = (organizationId, cb) => (dispatch, getState) => {
    const sfDocRef = firestore.collection("organizations").doc(organizationId)
    firestore.runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(sfDocRef).then((sfDoc) => {
            if (!sfDoc.exists) {
                throw "Document does not exist!";
            }
            const unconfirmedIBAN = sfDoc.get("bankAccount.unconfirmedIBAN"), status = sfDoc.get("bankAccount.status")
            if (unconfirmedIBAN && status === "pending") {
                transaction.update(sfDocRef, {
                    "bankAccount.IBAN": unconfirmedIBAN,
                    "bankAccount.unconfirmedIBAN": null,
                    "bankAccount.status": "confirmed",
                    "bankAccount.confirmedTimestamp": firebase.firestore.FieldValue.serverTimestamp(),
                })
            }
        });
    }).then(() => {
        cb(true)
        setTimeout(() => dispatch(queryOrganizations({}, false, true)), 2000)
    }).catch((error) => {
        console.error(error);
        cb(false, error)
    });
};

export const setOrganizationMiniMaxCustomerId = (organizationId, minimaxCustomerId, cb) => (dispatch, getState) => {
    const sfDocRef = firestore.collection("organizations").doc(organizationId)
    sfDocRef.update({minimaxCustomerId}).then(() => {
        cb(true)
        setTimeout(() => dispatch(queryOrganizations({}, false, true)), 2000)
    }).catch((error) => {
        console.error(error);
        cb(false, error)
    });
};

export const setOrganizationVatNum = (organizationId, vatNum, cb) => (dispatch, getState) => {
    const sfDocRef = firestore.collection("organizations").doc(organizationId)
    sfDocRef.update({vatNum}).then(() => {
        cb(true)
        setTimeout(() => dispatch(queryOrganizations({}, false, true)), 2000)
    }).catch((error) => {
        console.error(error);
        cb(false, error)
    });
};

export const setOrganizationVat = (organizationId, vatNum, cb) => (dispatch, getState) => {
    const sfDocRef = firestore.collection("organizations").doc(organizationId)
    sfDocRef.update({vatNum}).then(() => {
        cb(true)
        setTimeout(() => dispatch(queryOrganizations({}, false, true)), 2000)
    }).catch((error) => {
        console.error(error);
        cb(false, error)
    });
};

export const setOrganizationCountryCode = (organizationId, countryCode, cb) => (dispatch, getState) => {
    if(countryCode.length !== 2) return
    const sfDocRef = firestore.collection("organizations").doc(organizationId)
    sfDocRef.update({"address.countryCode": countryCode}).then(() => {
        cb(true)
        setTimeout(() => dispatch(queryOrganizations({}, false, true)), 2000)
    }).catch((error) => {
        console.error(error);
        cb(false, error)
    });
};

export const setOrganizationAccessGranted = (organizationId, accessGranted, cb) => (dispatch, getState) => {
    const sfDocRef = firestore.collection("organizations").doc(organizationId)
    sfDocRef.update({accessGranted}).then(() => {
        cb(true)
        setTimeout(() => dispatch(queryOrganizations({}, false, true)), 2000)
    }).catch((error) => {
        console.error(error);
        cb(false, error)
    });
};
