import {firestore} from '../firebase';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

export const listenForNotifications = () => (dispatch, getState) => {
    const state = getState(), uid = state.firebase.auth.uid, prevListener = state.notifications.listener;
    if(prevListener) prevListener(); // unsubscribe
    const listener = firestore.collection("notifications")
        .where("uid", "==", uid)
        .orderBy("timestamp", "desc")
        .limit(10)
        .onSnapshot(snap => {
            dispatch({
                type: GET_NOTIFICATIONS,
                ordered: snap.docs,
                listener
            })
        }, err => console.error(err))
};

/*
export const getOrganization = (organizationId, cb) => (dispatch, getState) => {
    firestore.collection("organizations").doc(organizationId).get().then(doc => {
        dispatch({
            type: GET_ORGANIZATION,
            id: doc.id,
            data: {...doc.data(), id: doc.id, _doc: doc}
        })
        cb()
    }).catch(err => {
        console.error(err)
        cb()
    })
};
 */
