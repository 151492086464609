import React, {Component} from "react";
import {connect} from "react-redux";
import {isMobile} from "../components/utils";
import {withTranslation} from "react-i18next";
import {filterReceivedInvoicesByCompany, getReceivedInvoices, payInvoices} from "../actions/receivedInvoices";
import MaterialIcon from '@material/react-material-icon';
import Dialog, {DialogButton, DialogContent, DialogFooter,} from '@material/react-dialog';
import Table from "../components/Table";

const tableColumns = [
    {
        name: "receivedInvoiceId",
        label: "ID",
        sortable: false
    },
    {
        name: "issuedDate",
        label: "Issued",
        sortable: false,
        type: "timestmap"
    },
    {
        name: "receivedDate",
        label: "Received",
        sortable: false,
        type: "timestmap"
    },
    {
        name: "dueDate",
        label: "Due date",
        sortable: false,
        type: "timestmap"
    },
    {
        name: "transactionDate",
        label: "Transaction date",
        sortable: false,
        type: "timestmap"
    },
    {
        name: "issuer.name",
        label: "Issuer",
        sortable: false,
    },
    {
        name: "invoiceAmount",
        label: "Total",
        sortable: false,
        type: "money"
    },
    {
        name: "_paymentStatus",
        label: "Status",
        sortable: false,
    }
]

class AppBuyerReceivedInvoices extends Component {
    state = {};

    render() {
        let {t, ordered = [], filterByCustomer} = this.props

        //  //this.props.match.params.id
        return (
            <div>
                <div className="mdc-layout-grid">
                    <div className="mdc-layout-grid__inner">
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                            <div className="table-header">
                                <div className="table-header__title">
                                    <h2 className="mdc-typography--headline5 title title--border table-header__title-text">
                                        Received
                                        invoices{filterByCustomer && filterByCustomer.companyId ? ": " + filterByCustomer.companyId : ""}
                                    </h2>
                                </div>
                                <div className="table-header__filters">
                                    {filterByCustomer ? (
                                        <div className="mdc-chip" onClick={e => this.filterByCompany(e)}>
                                            <MaterialIcon className="mdc-chip__icon mdc-chip__icon--leading"
                                                          role="button" icon="close"/>
                                            <span className="mdc-chip__text">{filterByCustomer.companyId}</span>
                                        </div>) : (<span/>)}
                                </div>
                            </div>

                            <div className="table-responsive">
                                <Table ordered={ordered} columns={tableColumns} disableSelection
                                       onAction={this._onTableAction.bind(this)}/>
                            </div>
                        </div>
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 flex flex--justify-center">
                            <button type="button" onClick={this._loadMore.bind(this)}
                                    className="button button--unelevated"
                                    hidden={this.props.loadMoreExhausted}>{t('loadMore')}</button>
                        </div>
                    </div>
                </div>
                <Dialog
                    onClose={(action) => this._onDialogAction(action)}
                    open={this.state.isOpen}>
                    <DialogContent>
                        <table>
                            <tbody>
                            <tr>
                                <td>Total</td>
                                <td align="right">{this.state.amount}</td>
                            </tr>
                            <tr>
                                <td>Financing costs</td>
                                <td align="right">{this.state.financingCosts}</td>
                            </tr>
                            <tr>
                                <td><b>Payout amount</b></td>
                                <td align="right"><b>{this.state.payOutAmount}</b></td>
                            </tr>
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton action='dismiss'>Cancel</DialogButton>
                        <DialogButton action='accept' isDefault>Confirm</DialogButton>
                    </DialogFooter>
                </Dialog>
            </div>
        )
    }

    _onDialogAction(action) {
        if (action === 'accept') {
            //console.log("calling server with", this.state.invoices);
            this.props.dispatch(payInvoices(this.state.invoices))
        }
        this.setState({isOpen: false})
    }

    _onTableAction(checkedMap) {
        let invoices = [], amount = window.currency(0, {symbol: '€'}), payOutAmount, financingCosts
        for (let id in checkedMap) {
            if (checkedMap.hasOwnProperty(id) && checkedMap[id] === true) {
                invoices.push(id)
                let invoice = this.props.invoices[id];
                amount = amount.add(invoice.get("invoiceAmount"))

            }
        }
        payOutAmount = amount.multiply(0.91)
        payOutAmount = payOutAmount.subtract(2.0)
        financingCosts = amount.subtract(payOutAmount)
        this.setState({
            isOpen: true,
            payOutAmount: payOutAmount.format(),
            financingCosts: financingCosts.format(),
            amount: amount.format(),
            invoices
        })
    }

    filterByCompany(e, id, name) {
        e.preventDefault();
        this.props.dispatch(filterReceivedInvoicesByCompany(id ? {id, name} : null))
    }

    _loadMore() {
        this.props.dispatch(getReceivedInvoices(true));
    }

    onRowClick(id) {
        if (isMobile()) {
            this.setState({redirect: id});
        }
    }

    componentDidMount() {
        this.props.dispatch(filterReceivedInvoicesByCompany(this.props.match.params.id ? {companyId: this.props.match.params.id} : null))
    }
}

const mapStateToProps = state => {
    return {
        firebase: state.firebase,
        filterByCustomer: state.receivedInvoices.filterByCustomer,
        loadMoreExhausted: state.receivedInvoices.loadMoreExhausted,
        invoices: state.receivedInvoices,
        ordered: state.receivedInvoices.ordered
    };
};

export default connect(mapStateToProps)(withTranslation("app")(AppBuyerReceivedInvoices));
