import React, {Component} from 'react';
import Checkbox from "@material/react-checkbox";
import Button from "@material/react-button";
import MaterialIcon from "@material/react-material-icon";
import {formatDate, formatMoney} from "./utils";
import IconButton from "@material/react-icon-button";
import {isDev} from "../firebase";

class Table extends Component {
    state = {
        checkedMap: {},
    };

    render() {
        let {
                ordered = [],
                columns = [],
                actionDownload = false,
                disableSelection = false,
                onAction = () => {
                },
            } = this.props, {
                checkedMap = {}
            } = this.state,
            _checkedValues = Object.values(checkedMap),
            checked = _checkedValues.length > 0 && _checkedValues.indexOf(false) === -1,
            indeterminate = !checked && _checkedValues.indexOf(true) >= 0
        return (
            <table
                className={"sortable sort table table--no-wrap" + ((checked || indeterminate) ? " table--actions-visible" : "")}>
                <thead>
                <tr className="mdc-typography--body2 table__head-row">
                    {!disableSelection && (<th className="no-sort">
                        <Checkbox
                            checked={checked}
                            indeterminate={indeterminate}
                            onChange={e => this._onCheckAll(e)}
                        />
                        <div className="table-actions">
                            <Button
                                icon={<MaterialIcon icon="request_quote"/>}
                                onClick={() => onAction(checkedMap)}
                                unelevated dense>
                                Payout
                            </Button>
                        </div>
                    </th>)}
                    {columns.map(({name, label, sortable}) => {
                        if (sortable) return <th key={name} className="sort-header sort-header--desc no-sort bold">
                                <span className="sort-header__text">
                                    {label}
                                    <span className="sort-header__icon">
                                        <MaterialIcon className="sort-header__icon-asc" role="button"
                                                      icon="arrow_upward"/>
                                        <MaterialIcon className="sort-header__icon-desc" role="button"
                                                      icon="arrow_downward"/>
                                    </span>
                                </span>
                        </th>
                        return <th key={name} className="no-sort">{label}</th>
                    })}
                    <th className="no-sort"/>
                </tr>
                </thead>
                <tbody>{ordered.map((data, index) => {
                    let doc = data._doc, selectable = data.selectable;
                    return <tr className="mdc-typography--body1" key={data.id}>
                        {!disableSelection && (<td><Checkbox
                            disabled={!selectable}
                            nativeControlId={'checkbox-' + data.id}
                            checked={checkedMap[data.id] === true}
                            onChange={e => this._onCheckSingle(e, data.id)}
                        /></td>)}
                        {columns.map(({name, label, type}) => {
                            let value = data[name] || doc.get(name),
                                key = data.id + name;
                            if (!value) return (<td key={key}/>)
                            if (type === "timestmap") return (<td key={key}>{formatDate(value.toMillis())}</td>)
                            else if (type === "money") return (<td key={key}>{formatMoney(value) + " €"}</td>)
                            else if (type === "boolean") return (
                                <td key={key}>{value === true ? "True" : "False"}</td>)
                            return (<td key={key}>{value}</td>)
                        })}
                        <td align={"right"}>
                            {data.displayActions ? (data.action === "download" ? (<a
                                type="button"
                                target="_blank"
                                className="mdc-button mdc-button--unelevated mdc-button--dense"
                                download
                                href={data.downloadUri}>
                                Download
                            </a>) : (data.action === "view") ? (<IconButton className="icon-button--dense"
                                                                            onClick={() => onAction({id: data.id})}
                            ><MaterialIcon icon='pageview'/></IconButton>) : (<Button
                                icon={<MaterialIcon icon="request_quote"/>}
                                onClick={() => onAction({[data.id]: true})}
                                unelevated dense disabled={!!doc.get("payoutStatus")}>
                                Payout
                            </Button>)) : actionDownload ? (<div>
                                {/*<Button className="mdc-button--on-primary"
                                        icon={<MaterialIcon icon="edit"/>}
                                        onClick={() => onAction(data)}
                                        dense>
                                    Edit
                                </Button>*/}
                                <a
                                    type="button"
                                    target="_blank"
                                    className="mdc-button mdc-button--unelevated mdc-button--dense"
                                    href={(isDev ? "https://europe-west1-test-scf-ff293.cloudfunctions.net" : "https://europe-west1-supply-chain-financing.cloudfunctions.net") + "/downloadXMLUPN?financingId=" + encodeURIComponent(doc.id) + "&organizationId=" + encodeURIComponent(data.receiverOrganizationId)}
                                    download={'upn-' + data.id + '.xml'}>
                                    Download UPN
                                </a>
                            </div>) : (<span/>)}
                        </td>
                    </tr>
                })}</tbody>
            </table>
        );
    }

    _onCheckAll(e) {
        const checked = e.target.checked, checkedMap = {};
        if (this.props.ordered) {
            for (let i = 0; i < this.props.ordered.length; i++) {
                let it = this.props.ordered[i]
                if (it.selectable) checkedMap[it.id] = checked
            }
        }
        this.setState({
            checkedMap
        })
    }

    _onCheckSingle(e, id) {
        const checked = e.target.checked, {checkedMap} = this.state;
        if (this.props.ordered) {
            for (let i = 0; i < this.props.ordered.length; i++) {
                let it = this.props.ordered[i]
                if (it.selectable) checkedMap[it.id] = checkedMap[it.id] || false
            }
        }
        checkedMap[id] = checked
        this.setState({
            checkedMap: {...checkedMap}
        })
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (prevProps.ordered !== this.props.ordered) {
            // update checkboxes, add to checkedMap only if row is selectable
            const checkedMap = {};
            if (this.props.ordered) {
                for (let i = 0; i < this.props.ordered.length; i++) {
                    let it = this.props.ordered[i]
                    if (it.selectable) checkedMap[it.id] = false
                }
            }
            this.setState({
                checkedMap
            })
        }
        return null
    }

    // defined only because of error when not using with getSnapshotBeforeUpdate
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.ordered !== this.props.ordered) {
            // update checkboxes, add to checkedMap only if row is selectable
            const checkedMap = {};
            if (this.props.ordered) {
                for (let i = 0; i < this.props.ordered.length; i++) {
                    let it = this.props.ordered[i]
                    if (it.selectable) checkedMap[it.id] = false
                }
            }
            this.setState({
                checkedMap
            })
        }
    }
}

export default Table;
