export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const openDialog = (dialog, onAction) => {
    return {
        type: OPEN_DIALOG,
        dialog, onAction
    }
};
export const closeDialog = () => {
    return {
        type: CLOSE_DIALOG
    }
};