import {GET_NOTIFICATIONS} from '../actions/notifications';

const INITIAL_STATE = {
    ordered: [],
    listener: null
};

const notifications = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return {
                ...state,
                ordered: action.ordered,
                listener: action.listener
            };
        default:
            return state;
    }
};
export default notifications;
