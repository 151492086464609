import React, {Component} from "react";
import {connect} from "react-redux";
import {isMobile} from "../components/utils";
import {withTranslation} from "react-i18next";
import MaterialIcon from '@material/react-material-icon';
import Dialog, {DialogButton, DialogContent, DialogFooter, DialogTitle,} from '@material/react-dialog';
import Table from "../components/Table";
import {queryOrganizations} from "../actions/organizations";
import Tab from "@material/react-tab";
import TabBar from "@material/react-tab-bar";
import ViewOrganization from "../components/ViewOrganization";


const tableColumns = [
    {
        name: "id",
        label: "ID",
        sortable: false
    },
    {
        name: "name",
        label: "Name",
        sortable: false
    },
    {
        name: "bankAccount.status",
        label: "IBAN status",
        sortable: false,
    },
    /*{
        name: "bankAccount.IBAN",
        label: "IBAN",
        sortable: false,
    },*/
    {
        name: "minimaxCustomerId",
        label: "MiniMax ID",
        sortable: false,
    },
    {
        name: "provider",
        label: "Issued Inv. Provider",
        sortable: false
    },
    {
        name: "providerConnectedId",
        label: "Provider Org. ID",
        sortable: false
    },
    {
        name: "accessByVatNum",
        label: "Access By VAT",
        sortable: false
    },
    {
        name: "accessGranted",
        label: "Access Granted",
        sortable: false,
        type: "boolean",
    },
]

class AppAdminOrganizations extends Component {
    state = {
        isOpen: false,
        activeTab: 0,
    };

    render() {
        let {t, ordered = []} = this.props
        return (
            <div>
                <div className="mdc-layout-grid">
                    <div className="mdc-layout-grid__inner">
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                            <div className="table-header">
                                <div className="table-header__title">
                                    <h2 className="mdc-typography--headline5 title title--border table-header__title-text">
                                        Organizations
                                    </h2>
                                </div>
                                <div className="table-header__filters">
                                    {this.props.filterByCustomer ? (
                                        <div className="mdc-chip" onClick={e => this.filterByCompany(e)}>
                                            <MaterialIcon className="mdc-chip__icon mdc-chip__icon--leading"
                                                          role="button" icon="close"/>
                                            <span className="mdc-chip__text">{this.props.filterByCustomer.name}</span>
                                        </div>) : (<span/>)}
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table ordered={ordered} columns={tableColumns} disableSelection
                                       onAction={this._onTableAction.bind(this)}/>
                            </div>
                        </div>
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 flex flex--justify-center">
                            <button type="button" onClick={this._loadMore.bind(this)}
                                    className="button button--unelevated"
                                    hidden={this.props.loadMoreExhausted}>{t('loadMore')}</button>
                        </div>
                    </div>
                </div>
                <Dialog
                    onClose={(action) => this._onDialogAction(action)}
                    open={this.state.isOpen}>
                    <DialogTitle>{this.state.selectedOrganization && this.props.organizations[this.state.selectedOrganization] ? this.props.organizations[this.state.selectedOrganization].name : ''}</DialogTitle>
                    <DialogContent>
                        <ViewOrganization onClose={this._onViewClose.bind(this)} organizationId={this.state.selectedOrganization}/>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton action='dismiss' isDefault>Cancel</DialogButton>
                    </DialogFooter>
                </Dialog>
            </div>
        )
    }

    _onViewClose() {
        this.setState({isOpen: false})
        this.props.dispatch(queryOrganizations(undefined, false, true))
    }

    // todo:
    _onDialogAction(action) {
        if (action === 'accept') {
            /* console.log("calling server with", this.state.invoices);
             this.props.dispatch(payInvoices(this.state.invoices))*/
        }
        this.setState({isOpen: false})
    }

    _onTableAction(details) {
        console.log(details)
        this.setState({selectedOrganization: details.id, isOpen: true})
    }

    filterByCompany(e, id, name) {
        e.preventDefault();
        this.props.dispatch(queryOrganizations())
    }

    _loadMore() {
        this.props.dispatch(queryOrganizations(undefined, true));
    }

    onRowClick(id) {
        if (isMobile()) {
            this.setState({redirect: id});
        }
    }

    componentDidMount() {
        this.props.dispatch(queryOrganizations(undefined, false, true))
    }
}

const mapStateToProps = state => {
    return {
        firebase: state.firebase,
        loadMoreExhausted: state.organizations.loadMoreExhausted,
        ordered: state.organizations.ordered,
        organizations: state.organizations
    };
};

export default connect(mapStateToProps)(withTranslation("app")(AppAdminOrganizations));
