import React, {Component} from "react";
import {Trans, withTranslation} from "react-i18next";

class AppSentConfirmation extends Component {
    render() {
        const {t} = this.props;
        return (
            <div>
                <div className="Register-Content-Header">
                    <Trans i18nKey='resetPasswordTitle'>
                        <h1 className="Register-Heading">{t('checkYourEmailTitle')}</h1>
                    </Trans>
                    <Trans i18nKey='resetPasswordSubtitle'>
                        <span className="Register-Subtitle">{t('checkYourEmailText')}</span>
                    </Trans>
                    <div className="Register-Content-Body">

                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation('app')(AppSentConfirmation);