import {firestore} from "../firebase";

export const GET_BILLING_HISTORY = "GET_BILLING_HISTORY"

/*
Manage Nekster Finance billing: view monthly billing summaries, download invoices
 */


export const getBillingHistory = () => (dispatch, getState) => {
    const state = getState(), limit = state.billing.limit, organizationId = state.organizations.active,
        updated = (new Date()).getTime(),
        doQuery = (query) => {
            query.get()
                .then(snap => {
                    const ordered = [], dataMap = {};
                    snap.forEach(_doc => {
                        let data = _doc.data()
                        ordered.push({
                            ...data,
                            id: _doc.id,
                            _doc
                        })
                        dataMap[_doc.id] = _doc
                    })
                    dispatch({
                        type: GET_BILLING_HISTORY,
                        ordered, dataMap, updated
                    })
                })
                .catch(err => {
                    console.error(err)
                    console.log(err.code)
                    if (err.code === "permission-denied") {
                        /* dispatch({
                             type: GET_ISSUED_INVOICES_PERMISSION_ERROR
                         })*/
                    }
                })
        }

    let query = firestore.collection("financing-invoices")
        .where("receiverOrganizationId", "==", organizationId)
        .orderBy("createdAt", "desc")
        .limit(limit)

    doQuery(query)
}
