import Dialog, {DialogButton, DialogContent, DialogFooter, DialogTitle} from "@material/react-dialog";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import LoadingDialog from "./LoadingDialog";
import {
    acceptPlatformAgreement,
    fetchPlatformAgreementVersions,
    platformAgreementProcessing,
    platformAgreementVersions
} from "../store/agreements";

const PlatformAgreements = () => {
    const [agreementsToShow, setAgreementsToShow] = useState([])
    const [activeAgreement, setActiveAgreement] = useState('')
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [dialogHeight, setDialogHeight] = useState(400)

    const dispatch = useDispatch()
    const {termsOfService, privacyPolicy} = useSelector(platformAgreementVersions)
    const isProcessing = useSelector(platformAgreementProcessing)

    const isProfileLoaded = useSelector(state => state.firebase.profile.isLoaded);
    const profile = useSelector(state => state.firebase.profile);
    const agreements = useSelector(state => state.firebase.profile.agreements);

    useEffect(() => {
        dispatch(fetchPlatformAgreementVersions())
    }, [])

    useEffect(() => {
        if (isProfileLoaded && termsOfService && privacyPolicy) {
            const showAgreements = []
            if (agreements) {
                if (agreements.termsOfService !== termsOfService) showAgreements.push(termsOfService)
                if (agreements.privacyPolicy !== privacyPolicy) showAgreements.push(privacyPolicy)
            } else if (profile.email) {
                // user has no agreements ... let's display
                showAgreements.push(termsOfService, privacyPolicy)
            }
            setAgreementsToShow(showAgreements)
        }
    }, [isProfileLoaded, termsOfService, privacyPolicy])

    useEffect(() => {
        if (!isProcessing && !isDialogOpen && agreementsToShow.length > 0) {
            const _agreementsToShow = [...agreementsToShow]
            const agreementToShow = _agreementsToShow.shift() // removes first el in array and returns it

            setActiveAgreement(agreementToShow)
            setIsDialogOpen(true)
            setAgreementsToShow(_agreementsToShow)
        }
    }, [agreementsToShow, isDialogOpen, isProcessing])

    // TODO: change this as it sometimes does not work
    const dialogRef = useRef()
    useEffect(() => {
        if (dialogRef && dialogRef.current.dialogElement) {
            const rect = dialogRef.current.dialogElement.current.getBoundingClientRect();
            setDialogHeight(rect.height > 200 ? rect.height - 200 : 400)
        }
    }, [dialogRef])

    function onDialogClose(action) {
        if (action === "accept") {
            dispatch(acceptPlatformAgreement(activeAgreement === termsOfService ? "termsOfService" : "privacyPolicy", activeAgreement))
        }
        setIsDialogOpen(false)
    }

    return <div>
        <Dialog
            ref={dialogRef}
            onClose={onDialogClose}
            open={isDialogOpen}
            scrimClickAction={""}
            className="dialog--wide">
            <DialogTitle>
                {activeAgreement === termsOfService ? "Changes to our Terms of Service" : activeAgreement === privacyPolicy ?
                    "Changes to our Privacy Policy" : null}
            </DialogTitle>
            <DialogContent>
                {activeAgreement === termsOfService ?
                    <AgreementContentTOS height={dialogHeight}/> : activeAgreement === privacyPolicy ?
                        <AgreementContentPrivacy height={dialogHeight}/> : null}
            </DialogContent>
            <DialogFooter>
                <DialogButton className="button--dismiss" action='dismiss'>Dismiss</DialogButton>
                <DialogButton action='accept' isDefault>Accept</DialogButton>
            </DialogFooter>
        </Dialog>
        <LoadingDialog open={isProcessing}/>
    </div>
}

export const AgreementContentTOS = props => {
    return <iframe width="748" height={props.height}
                   src="https://storage.googleapis.com/test-scf-ff293.appspot.com/agreements/2021-04-16/terms-of-service.pdf"/>
}

export const AgreementContentPrivacy = () => {
    return <div>
        <h1>Privacy Policy</h1>
        <p>1. General
            These Terms and Conditions ("Agreement") governs the use
            of the services ("Service") that are made available by
            Website.com Solutions Inc. ("Website.com", "we" or "us").
            These Terms and Conditions represent the whole agreement
            and understanding between Website.com and the individual
            or entity who subscribes to our service ("Subscriber" or "you").
            PLEASE READ THIS AGREEMENT CAREFULLY. By submitting
            your application and by your use of the Service, you agree to
            comply with all of the terms and conditions set out in this
            Agreement. Website.com may terminate your account at any
            time, with or without notice, for conduct that is in breach of
            this Agreement, for conduct that Website.com believes is harmful
            to its business, or for conduct where the use of the Service is
            harmful to any other party.
            Website.com may, in its sole discretion, change or modify this
            Agreement at any time, with or without notice. Such changes
            or modifications shall be made effective for all Subscribers
            upon posting of the modified Agreement to this web address
            (URL): http://www.website.com/terms-and-conditions/. You
            are responsible to read this document from time to time to
            ensure that your use of the Service remains in compliance with
            this Agreement.
            2. Services
            Website.com offers Subscribers domain name registration,
            website hosting, and email hosting services for the duration of
            the service term purchased from Website.com.
            Services are provided on the basis of facility and equipment
            availability. Website.com reserves the right to modify, change,
            or discontinue any aspect of the Services at any time.
            Access to the web and email servers is terminated upon expiry
            of the Service.
            Details regarding your account can be found in your account
            control panel (https://www.website.com/sign-in/)
            3. Web Hosting
            All Website.com web hosting accounts will display a "Coming
            Soon" web page by default when activated. This page informs
            visitors that the Subscriber has recently setup their account with
            Website.com. The "Coming Soon" default index web page may
            be removed by the Subscriber at any time once they have access
            to the web server. The "Coming Soon" web page may include,
            but without limitation to, the following:
            Links to additional products and services offered by Website.com.
            Advertisements for products and services offered by third-parties.
            An internet search engine interface.
            All web page error requests (such as 403 errors or 404 errors)
            will direct traffic to a default Website.com error page. This error
            page informs visitors that the web page they are looking for
            cannot be found, and may include, but without limitation to, the
            following:

            1. General
            These Terms and Conditions ("Agreement") governs the use
            of the services ("Service") that are made available by
            Website.com Solutions Inc. ("Website.com", "we" or "us").
            These Terms and Conditions represent the whole agreement
            and understanding between Website.com and the individual
            or entity who subscribes to our service ("Subscriber" or "you").
            PLEASE READ THIS AGREEMENT CAREFULLY. By submitting
            your application and by your use of the Service, you agree to
            comply with all of the terms and conditions set out in this
            Agreement. Website.com may terminate your account at any
            time, with or without notice, for conduct that is in breach of
            this Agreement, for conduct that Website.com believes is harmful
            to its business, or for conduct where the use of the Service is
            harmful to any other party.
            Website.com may, in its sole discretion, change or modify this
            Agreement at any time, with or without notice. Such changes
            or modifications shall be made effective for all Subscribers
            upon posting of the modified Agreement to this web address
            (URL): http://www.website.com/terms-and-conditions/. You
            are responsible to read this document from time to time to
            ensure that your use of the Service remains in compliance with
            this Agreement.
            2. Services
            Website.com offers Subscribers domain name registration,
            website hosting, and email hosting services for the duration of
            the service term purchased from Website.com.
            Services are provided on the basis of facility and equipment
            availability. Website.com reserves the right to modify, change,
            or discontinue any aspect of the Services at any time.
            Access to the web and email servers is terminated upon expiry
            of the Service.
            Details regarding your account can be found in your account
            control panel (https://www.website.com/sign-in/)
            3. Web Hosting
            All Website.com web hosting accounts will display a "Coming
            Soon" web page by default when activated. This page informs
            visitors that the Subscriber has recently setup their account with
            Website.com. The "Coming Soon" default index web page may
            be removed by the Subscriber at any time once they have access
            to the web server. The "Coming Soon" web page may include,
            but without limitation to, the following:
            Links to additional products and services offered by Website.com.
            Advertisements for products and services offered by third-parties.
            An internet search engine interface.
            All web page error requests (such as 403 errors or 404 errors)
            will direct traffic to a default Website.com error page. This error
            page informs visitors that the web page they are looking for
            cannot be found, and may include, but without limitation to, the
            following:
        </p>
    </div>
}

export default PlatformAgreements;
