import firebase from "../firebase";
import {API_URL} from "../config";
import {activeOrganizationSelector} from "../reducers/organizations";

export const GET_ISSUED_INVOICES = 'GET_ISSUED_INVOICES'
export const FILTER_ISSUED_INVOICES_BY_COMPANY = 'FILTER_ISSUED_INVOICES_BY_COMPANY'
export const GET_ISSUED_INVOICES_PERMISSION_ERROR = 'GET_ISSUED_INVOICES_PERMISSION_ERROR'

const organizationId = "any";

/*export const syncIssuedInvoices = (loadMore = false) => (dispatch, getState) => {
    const state = getState(), limit = state.issuedInvoices.limit, ordered = state.issuedInvoices.ordered,
        filterByCustomer = state.issuedInvoices.filterByCustomer, updated = (new Date()).getTime(),
        accessToken = state.firebase.auth.stsTokenManager.accessToken,
        doQuery = (query, replace = false) => {
            query.get()
                .then(snap => {
                    dispatch({
                        type: GET_ISSUED_INVOICES, replace,
                        ordered: snap.docs, loadMoreExhausted: (snap.empty || snap.size < limit), updated
                    })
                }).catch(err => console.error(err))
        }

    if (ordered.length > 0 && !loadMore) return

    let query = firebase.firestore().collection("organizations/any/issued-invoices")
        .orderBy("issuedDate", "desc").limit(limit)

    if (filterByCustomer) query = query.where("customer.id", "==", filterByCustomer.id)
    if (loadMore && ordered.length > 0) query = query.startAfter(ordered[ordered.length - 1])

    doQuery(query)

    return fetch(API_URL + `/${organizationId}/issued-invoices:sync?limit=${limit}&offset=${ordered.length}`, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + accessToken,
        },
    }).then(res => {
        if(loadMore) return
        if (getState().issuedInvoices.updated === updated) doQuery(query, true)
    }).catch(err => {
        console.error(err);
    })
}*/

export const _status = {
    "confirmed": "",
    "draft": "Osnutek",
    "rejected": "Zavrnjeno",
}, _paymentStatus = {
    "paid": "Paid",
    "partiallyPaid_overdue": "Overdue, partially paid",
    "partiallyPaid_notDue": "Partially paid",
    "unpaid_overdue": "Overdue",
    "unpaid_notDue": "Unpaid",
    "draft": "Draft",
    "advancePayment": "Advance payment",
    "financed": "Financed",
}, _supportedCurrencies = {
    'EUR': '€'
};

export const refreshIssuedInvoices = () => (dispatch) => {
    dispatch({
        type: GET_ISSUED_INVOICES, replace: true,
        ordered: [], dataMap: {}, loadMoreExhausted: false, updated: (new Date()).getTime()
    })
    dispatch(geIssuedInvoices())
}

export const geIssuedInvoices = (loadMore = false, replace = false) => (dispatch, getState) => {
    const state = getState(), limit = state.issuedInvoices.limit, ordered = state.issuedInvoices.ordered,
        filterByCustomer = state.issuedInvoices.filterByCustomer, updated = (new Date()).getTime(),
        {accessByVatNum} = activeOrganizationSelector(state),
        doQuery = (query, replace = false) => {
            query.get()
                .then(snap => {
                    const ordered = [], dataMap = {};
                    snap.forEach(_doc => {
                        let data = _doc.data(), selectable = false;

                        switch (data.paymentStatus) {
                            case "partiallyPaid_overdue":
                            case "partiallyPaid_notDue":
                            case "unpaid_overdue":
                            case "unpaid_notDue":
                                selectable = true
                        }

                        if (data.payoutStatus) selectable = false

                        ordered.push({
                            ...data,
                            _paymentStatus: _paymentStatus[data.paymentStatus],
                            selectable,
                            id: _doc.id,
                            displayActions: selectable,
                            _doc
                        })
                        dataMap[_doc.id] = _doc
                    })
                    dispatch({
                        type: GET_ISSUED_INVOICES, replace,
                        ordered, dataMap, loadMoreExhausted: (snap.empty || snap.size < limit), updated
                    })
                })
                .catch(err => {
                    console.error(err)
                    console.log(err.code)
                    if (err.code === "permission-denied") {
                        dispatch({
                            type: GET_ISSUED_INVOICES_PERMISSION_ERROR
                        })
                    }
                })
        }

    if (!accessByVatNum) return

    if (ordered.length > 0 && !loadMore && !replace) return

    let query = firebase.firestore().collection("received-invoices")
        .orderBy("issuedDate", "desc")
        .limit(limit)
        .where("issuer.accessBy", "array-contains-any", [accessByVatNum])

    if (filterByCustomer) query = query.where("customer.id", "==", filterByCustomer.id)
    if (loadMore && ordered.length > 0) query = query.startAfter(ordered[ordered.length - 1]._doc)

    doQuery(query, replace)
}

export const getIssuedInvoice = (issuedInvoiceId, callback) => (dispatch, getState) => {
    const state = getState(), accessToken = state.firebase.auth.stsTokenManager.accessToken
    return fetch(API_URL + `/${organizationId}/issued-invoice/${issuedInvoiceId}`, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + accessToken,
        },
    }).then(res => {
        return res.json()
    }).then((data = {}) => {
        console.log()
        callback(data.EInvoiceAttachment)
    }).catch(err => {
        console.error(err);
    })
}

export const filterIssuedInvoicesByCompany = customer => dispatch => {
    dispatch({
        type: FILTER_ISSUED_INVOICES_BY_COMPANY,
        customer
    })
    //dispatch(syncIssuedInvoices())
}
