import firebase, {functions} from "../firebase";
import {_paymentStatus} from "./issuedInvoices";

export const GET_RECEIVED_INVOICES = 'GET_RECEIVED_INVOICES'
export const FILTER_RECEIVED_INVOICES_BY_COMPANY = 'FILTER_RECEIVED_INVOICES_BY_COMPANY'
export const SET_PROCESSING = 'SET_PROCESSING'

/*export const syncReceivedInvoices = (loadMore = false) => (dispatch, getState) => {
    const state = getState(), limit = state.receivedInvoices.limit, ordered = state.receivedInvoices.ordered,
        filterByCustomer = state.receivedInvoices.filterByCustomer, updated = (new Date()).getTime(),
        accessToken = state.firebase.auth.stsTokenManager.accessToken,
        doQuery = (query, replace = false) => {
            query.get()
                .then(snap => {
                    dispatch({
                        type: GET_RECEIVED_INVOICES, replace,
                        ordered: snap.docs, loadMoreExhausted: (snap.empty || snap.size < limit), updated
                    })
                }).catch(err => console.error(err))
        }

    if(ordered.length > 0 && !loadMore) return

    let query = firebase.firestore().collection("organizations/any/received-invoices")
        .orderBy("issuedDate", "desc").limit(limit)

    if (filterByCustomer) query = query.where("customer.id", "==", filterByCustomer.id)
    if (loadMore && ordered.length > 0) query = query.startAfter(ordered[ordered.length - 1])

    doQuery(query)

    return fetch(API_URL + `/${organizationId}/received-invoices:sync?limit=${limit}&offset=${ordered.length}`, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + accessToken,
        },
    }).then(res => {
        if(loadMore) return
        if (getState().receivedInvoices.updated === updated) doQuery(query, true)
    }).catch(err => {
        console.error(err);
    })
}*/

export const getReceivedInvoices = (loadMore = false, replace = false) => (dispatch, getState) => {
    const state = getState(), limit = state.receivedInvoices.limit, ordered = state.receivedInvoices.ordered,
        filterByCustomer = state.receivedInvoices.filterByCustomer, updated = (new Date()).getTime(),
        organizationId = state.organizations.active,
        doQuery = (query, replace = false) => {
            query.get()
                .then(snap => {
                    const ordered = [], dataMap = {};
                    snap.forEach(_doc => {
                        let data = _doc.data(), selectable = !!data.payoutStatus, downloadUri = null, action = null;

                        if(data.financingRef) {
                            action = "download"
                            downloadUri = `https://europe-west1-nekster-finance-minimax-api.cloudfunctions.net/downloadXMLUPN?financingId=${data.financingRef.id}&organizationId=${organizationId}`
                        }

                        /*switch (data.paymentStatus) {
                            case "partiallyPaid_overdue":
                            case "partiallyPaid_notDue":
                            case "unpaid_overdue":
                            case "unpaid_notDue":
                                selectable = true
                        }*/

                        ordered.push({
                            ...data,
                            _paymentStatus: _paymentStatus[data.paymentStatus],
                            selectable,
                            id: _doc.id,
                            action,
                            downloadUri,
                            displayActions: selectable,
                            _doc
                        })
                        dataMap[_doc.id] = _doc
                    })

                    dispatch({
                        type: GET_RECEIVED_INVOICES, replace,
                        ordered, dataMap, loadMoreExhausted: (snap.empty || snap.size < limit), updated
                    })
                }).catch(err => console.error(err))
        }

    if (ordered.length > 0 && !loadMore) return

    let query = firebase.firestore().collection("received-invoices")
        .orderBy("issuedDate", "desc")
        .limit(limit)
        .where("organizationId", "==", organizationId)

    if (filterByCustomer) query = query.where("issuer.companyId", "==", filterByCustomer.companyId)
    if (loadMore && ordered.length > 0) query = query.startAfter(ordered[ordered.length - 1]._doc)

    doQuery(query, replace)
}

export const filterReceivedInvoicesByCompany = customer => dispatch => {
    dispatch({
        type: FILTER_RECEIVED_INVOICES_BY_COMPANY,
        customer
    })
    dispatch(getReceivedInvoices(false, true))
}

export const payInvoices = (invoices = [], cb = () => {}) => (dispatch, getState) => {
    const state = getState(),
        organizationId = state.organizations.active

    const _payInvoices = functions.httpsCallable("payInvoices")
    _payInvoices({
        invoices, organizationId
    }).then(res => {
        const data = res.data;
        console.log(res)
        if (res && res.data) {

        }
        cb()
    }).catch(err => {
        console.error(err)
        cb()
    })

}

export const setProcessing = () => {
    return {
        type: SET_PROCESSING,
    }
}
