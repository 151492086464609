import React, {Component} from 'react';
import {connect} from "react-redux";
import Button from "@material/react-button";
import Spinner from "./Spinner";
import TextField, {Input} from "@material/react-text-field";
import Checkbox from "@material/react-checkbox";
import {
    confirmOrganizationIBAN,
    setOrganizationAccessGranted,
    setOrganizationCountryCode,
    setOrganizationMiniMaxCustomerId,
    setOrganizationVatNum
} from "../actions/organizations";


class ViewOrganization extends Component {
    state = {
        loadingIBANConfirmation: false,
        loadingIDConfirmation: false,
        loadingVATConfirmation: false,
        loadingAccessConfirmation: false,
        minimaxCustomerId: "",
        countryCode: "",
        vatNum: "",
        accessGranted: false,
    };

    render() {
        const {organization = {}} = this.props, {
            loadingIBANConfirmation,
            loadingIDConfirmation,
            loadingCountryConfirmation,
            loadingVATConfirmation,
            loadingAccessConfirmation,
            minimaxCustomerId,
            accessByVatNum,
            accessGranted,
        } = this.state

        return (
            <div>
                <table className="table table--light table--dialog-width">
                    <tbody>
                    <tr>
                        <td>IBAN</td>
                        <td align="right">{organization.bankAccount ? organization.bankAccount.IBAN : '/'}</td>
                    </tr>
                    <tr>
                        <td>IBAN pending</td>
                        <td align="right">{organization.bankAccount ? organization.bankAccount.unconfirmedIBAN : '/'}</td>
                    </tr>
                    <tr>
                        <td>IBAN status</td>
                        <td align="right">
                            {organization.bankAccount?.status === "pending" ? (
                                <Button unelevated dense
                                        trailingIcon={loadingIBANConfirmation ? (<Spinner button/>) : ''}
                                        disabled={loadingIBANConfirmation}
                                        onClick={this._confirmIBAN.bind(this)}>Confirm</Button>
                            ) : organization.bankAccount?.status}
                        </td>
                    </tr>
                    <tr>
                        <td>Access By VAT ID</td>
                        <td align="right">{organization.accessByVatNum || '/'}</td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <div><span className="mdc-typography--caption">MiniMax Customer ID</span></div>
                <div className="list list--dialog-width list--vertical">
                    <div className="list-item">
                        <div className="mdc-typography--body1 list-item__text custom-account-list-item">
                            <TextField label='MiniMax ID' dense>
                                <Input value={this.state.minimaxCustomerId} disabled={loadingIDConfirmation}
                                       onChange={(e) => this.setState({minimaxCustomerId: e.currentTarget.value})}/>
                            </TextField>
                        </div>
                        <div
                            className="mdc-typography--body1 list-item__other list-item__other--align-right custom-account-list-item">
                            <Button unelevated dense
                                    trailingIcon={loadingIDConfirmation ? (<Spinner button/>) : ''}
                                    disabled={loadingIDConfirmation}
                                    onClick={this._confirmIDChange.bind(this)}>Save</Button>
                        </div>
                    </div>
                </div>
                <br/>
                <div><span className="mdc-typography--caption">Country Code</span>
                </div>
                <div className="list list--dialog-width list--vertical">
                    <div className="list-item">
                        <div className="mdc-typography--body1 list-item__text custom-account-list-item">
                            <TextField label='Country Code' dense>
                                <Input value={this.state.countryCode} disabled={loadingCountryConfirmation}
                                       onChange={(e) => this.setState({countryCode: e.currentTarget.value})}/>
                            </TextField>
                        </div>
                        <div
                            className="mdc-typography--body1 list-item__other list-item__other--align-right custom-account-list-item">
                            <Button unelevated dense
                                    trailingIcon={loadingCountryConfirmation ? (<Spinner button/>) : ''}
                                    disabled={loadingCountryConfirmation}
                                    onClick={this._confirmCountryChange}>Save</Button>
                        </div>
                    </div>
                </div>
                <br/>
                <div><span className="mdc-typography--caption">VAT</span>
                </div>
                <div className="list list--dialog-width list--vertical">
                    <div className="list-item">
                        <div className="mdc-typography--body1 list-item__text custom-account-list-item">
                            <TextField label='VAT' dense>
                                <Input value={this.state.vatNum} disabled={loadingVATConfirmation}
                                       onChange={(e) => this.setState({vatNum: e.currentTarget.value})}/>
                            </TextField>
                        </div>
                        <div
                            className="mdc-typography--body1 list-item__other list-item__other--align-right custom-account-list-item">
                            <Button unelevated dense
                                    trailingIcon={loadingVATConfirmation ? (<Spinner button/>) : ''}
                                    disabled={loadingVATConfirmation}
                                    onClick={this._confirmVATChange.bind(this)}>Save</Button>
                        </div>
                    </div>
                </div>
                <br/>
                <div><span className="mdc-typography--caption">Access to web platform</span></div>
                <div className="list list--dialog-width list--vertical">
                    <div className="list-item">
                        <div className="mdc-typography--body1 list-item__text custom-account-list-item">
                            <Checkbox
                                disabled={loadingAccessConfirmation}
                                nativeControlId='my-checkbox'
                                checked={this.state.accessGranted}
                                onChange={(e) => this.setState({accessGranted: e.target.checked})}
                            />
                            <label htmlFor='my-checkbox'>Access Granted</label>
                        </div>
                        <div
                            className="mdc-typography--body1 list-item__other list-item__other--align-right custom-account-list-item">
                            <Button unelevated dense
                                    trailingIcon={loadingAccessConfirmation ? (<Spinner button/>) : ''}
                                    disabled={loadingAccessConfirmation}
                                    onClick={this._confirmAccessChange.bind(this)}>Save</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _close() {
        if (this.props.onClose) this.props.onClose()
    }

    _confirmIBAN() {
        this.setState({loadingIBANConfirmation: true})
        this.props.dispatch(confirmOrganizationIBAN(this.props.organizationId, (success, error) => {
            this.setState({loadingIBANConfirmation: false})
            if (error) alert("There was an error submitting your request!")
            //this._close()
        }))
    }

    _confirmIDChange() {
        this.setState({loadingIDConfirmation: true})
        this.props.dispatch(setOrganizationMiniMaxCustomerId(this.props.organizationId, parseInt(this.state.minimaxCustomerId) || "", (success, error) => {
            this.setState({loadingIDConfirmation: false})
            if (error) alert("There was an error submitting your request!")
            //this._close()
        }))
    }

    _confirmCountryChange = () => {
        this.setState({loadingCountryConfirmation: true})
        this.props.dispatch(setOrganizationCountryCode(this.props.organizationId, this.state.countryCode || "", (success, error) => {
            this.setState({loadingCountryConfirmation: false})
            if (error) alert("There was an error submitting your request!")
            //this._close()
        }))
    }

    _confirmVATChange() {
        this.setState({loadingVATConfirmation: true})
        this.props.dispatch(setOrganizationVatNum(this.props.organizationId, this.state.vatNum || "", (success, error) => {
            this.setState({loadingVATConfirmation: false})
            if (error) alert("There was an error submitting your request!")
            //this._close()
        }))
    }

    _confirmAccessChange() {
        this.setState({loadingAccessConfirmation: true})
        this.props.dispatch(setOrganizationAccessGranted(this.props.organizationId, this.state.accessGranted === true, (success, error) => {
            this.setState({loadingAccessConfirmation: false})
            if (error) alert("There was an error submitting your request!")
            //this._close()
        }))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.organization !== this.props.organization && this.props.organization) {
            this.setState({
                minimaxCustomerId: this.props.organization.minimaxCustomerId || "",
                countryCode: this.props.organization.address.countryCode || "",
                vatNum: this.props.organization.vatNum || "",
                accessGranted: this.props.organization.accessGranted || false,
            })
        }
    }
}

const mapStateToProps = (state, props) => {
    return {
        organization: state.organizations[props.organizationId]
    };
};

export default connect(mapStateToProps)(ViewOrganization);
