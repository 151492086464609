import React, {useEffect, useState} from 'react';
import './i18n';
import './styles/global.scss';
import Register, {
    RegisterConnect,
    RegisterContent,
    RegisterFooter,
    RegisterHeader,
    RegisterInstead
} from "./pages/Register";
import {connect, useDispatch, useSelector} from "react-redux";
import App from "./pages/App";
import {getOrganization} from "./actions/organizations";
import Spinner from "./components/Spinner";
import {BrowserRouter as Router, Link, Redirect, Route, Switch} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import firebase, {functions} from "./firebase";
import {Trans, withTranslation} from "react-i18next";
import {isGlobalLoaderActive, setGlobalLoader} from "./store/loader";
import Analytics from "./components/Analytics";
import {createBrowserHistory} from "history";
import {setPendingInvitation} from "./store/invitation";

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const OrganizationProvider = () => {
    const [isLoading, setIsLoading] = useState(true)

    const dispatch = useDispatch()
    const detectedOrganizationId = useSelector(state => state.firebase.profile.organizationId);
    //const activeOrganizationId = useSelector(state => state.organizations.active);

    useEffect(() => {
        if (detectedOrganizationId) {
            setIsLoading(true)
            dispatch(getOrganization(detectedOrganizationId, () => setIsLoading(false)))
        } else {
            setIsLoading(false)
        }
    }, [detectedOrganizationId])

    /*useEffect(() => {
        //setIsLoading(false)
    }, [activeOrganizationId])*/

    return isLoading ? <Spinner fullHeight/> : <App/>
}

function useInterval(callback, delay) {
    const intervalId = React.useRef(null);
    const savedCallback = React.useRef(callback);
    React.useEffect(() => {
        savedCallback.current = callback;
    });
    React.useEffect(() => {
        const tick = () => savedCallback.current();
        if (typeof delay === 'number') {
            intervalId.current = window.setInterval(tick, delay);
            return () => window.clearInterval(intervalId.current);
        }
    }, [delay]);
    return intervalId.current;
}


// TODO: move 2fa check inside Login component ... in this component only check for 2fa state - redux auth store
// TODO: also make Login page use Spinner fullHeight so that login screen does not show on first load before user is checked

const Root = (props) => {
    const isLoading = useSelector(isGlobalLoaderActive);

    const [user, setUser] = useState(null)
    const [isUserAuthorized, setIsUserAuthorized] = useState(null)
    //const [isUserAuthorizing, setIsUserAuthorizing] = useState(null)

    const isProfileLoaded = useSelector(state => state.firebase.profile.isLoaded);
    const isProfileEmpty = useSelector(state => state.firebase.profile.isEmpty);

    useEffect(() => {
        if (isProfileLoaded) {
            if (isProfileEmpty) {
                // not logged in
                setIsUserAuthorized(false)
                dispatch(setGlobalLoader(false))
            } else {
                // logged in
                const {currentUser} = firebase.auth()
                if (!user || currentUser.uid !== user.uid) {
                    // activate user effect below
                    setUser(currentUser)
                }
            }
        } else {
            dispatch(setGlobalLoader(true))
        }
    }, [isProfileLoaded])

    useEffect(() => {
        //console.log("user changed", user)
        if (user) {
            // check if token contains custom claim twoFactor
            dispatch(setGlobalLoader(true))
            user.getIdTokenResult(false).then(idTokenResult => {
                const _isTwoFA = idTokenResult.claims.twoFactor === true;
                //console.log("ID TOKEN RESULT", idTokenResult)
                setIsUserAuthorized(_isTwoFA)
            }).catch(err => {
                console.error(err)
                setIsUserAuthorized(false)
            }).finally(() => dispatch(setGlobalLoader(false)))
        } else if (isProfileLoaded) {
            dispatch(setGlobalLoader(false))
        }
    }, [user])


    // LOGIN

    const [isFirstView, setIsFirstView] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);
    const [timeout, setTimeout] = useState(-1);
    const [verificationId, setVerificationId] = useState('');
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();


    useInterval(() => {
        setTimeout(timeout => timeout - 1);
    }, timeout > -1 ? 1000 : null);

    useEffect(() => {
        if (timeout === 60) {

            // verify
            const sendPhoneNumberVerification = functions.httpsCallable("sendPhoneNumberVerification")
            sendPhoneNumberVerification().then(res => {
                if (res.data && res.data.Id) {
                    setVerificationId(res.data.Id)
                    setError(null)
                } else {
                    setVerificationId('')
                    setError("There was an error processing your request. Please return to login page and try again")
                }
            }).catch(err => {
                console.error(err)
                setVerificationId('')
                setError("There was an error processing your request. Please return to login page and try again")
            })
        }
    }, [timeout]);

    const handleSubmit = e => {
        e.preventDefault();

        setFetching(true)

        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(userRec => {
                /*return userRec.user.getIdTokenResult(true).then((token) => {
                    if(token.claims.twoFactor) {
                        dispatch({type: SET_2FA, twoFactor: token.claims.twoFactor === true});
                    }
                })*/
                setFetching(false)
                setError(null)
                setIsFirstView(false)
                if (!isDev) setTimeout(60)
            })
            .catch(err => {
                console.error(err)
                let error;

                switch (err.code) {
                    case "auth/user-disabled":
                        error = "This user account has been disabled"
                        break
                    case "auth/wrong-password":
                        error = "The password is invalid or the user does not exist"
                        break
                    default:
                        error = err.message
                }

                setFetching(false)
                setError(error)
            });
    }

    const handleSubmitCode = e => {
        e.preventDefault();

        setFetching(true)

        const signInUserWith2FA = functions.httpsCallable("signInUserWith2FA")
        signInUserWith2FA({code, verificationId}).then(res => {
            if (res.data) {
                firebase
                    .auth()
                    .signInWithCustomToken(res.data)
                    .then(userRec => {
                        return userRec.user.getIdTokenResult(true).then((token) => {
                            return token.claims.twoFactor === true
                        })
                    })
                    .then(twoFactor => {
                        if (twoFactor) {
                            setIsUserAuthorized(true)
                            //window.location.reload() // can not reload as accept invitation would not work for signed out users anymore
                        } else {
                            let error = "Something went wrong. Please try again";
                            setFetching(false)
                            setError(error)
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        let error = "Something went wrong. Please try again";
                        setFetching(false)
                        setError(error)
                    });
            } else {
                let error = "Something went wrong. Please try again";
                setFetching(false)
                setError(error)
            }
        }).catch(err => {
            console.error(err)
            let error = "Something went wrong. Please try again";

            /*switch (err.code) {
                case "auth/user-disabled":
                    error = "This user account has been disabled"
                    break
                case "auth/wrong-password":
                    error = "The password is invalid or the user does not exist"
                    break
                default:
                    error = err.message
            }*/

            setFetching(false)
            setError(error)
        })
    }

    const signOut = () => {
        firebase.auth().signOut().finally(() => window.location.reload())
    }

    function renderLogin() {
        return () => {
            return isLoading ? <Spinner fullHeight/> : isUserAuthorized ? <Redirect to="/u/dashboard" push/> :
                <div className="Register">
                    <RegisterHeader/>
                    <RegisterContent>
                        <div className="Container Container--Stretch">
                            <form onSubmit={handleSubmit} hidden={!isFirstView}>
                                <div className="Register-Content-Header">
                                    <h1 className="Register-Heading">Log in to your account<b/></h1>
                                    <div className="Register-Content-Body">
                                        <div className="Register-Row">
                                            <div className="Register-FormField">
                                                <input type="text" name="username" className="Register-Input"
                                                       onChange={e => setEmail(e.target.value)}
                                                       placeholder={props.t("usernamePlaceholder")}
                                                       autoComplete="email"/>
                                            </div>
                                        </div>
                                        <div className="Register-Row">
                                            <div className="Register-FormField Register-FormField--Next">
                                                <input type="password" name="password" className="Register-Input"
                                                       placeholder={props.t("passwordPlaceholder")}
                                                       onChange={e => setPassword(e.target.value)}
                                                       autoComplete="current-password"/>

                                                <button disabled={fetching} type="submit" data-loading={fetching}
                                                        className="Button Button--Next">
                                                    <div className="Button__Loader"/>
                                                </button>
                                            </div>
                                            <span
                                                className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                                        </div>
                                        <div className="Register-Row"/>
                                        <Trans i18nKey='resetPasswordHere'>
                                            <Link className="Register-Subtitle" to={"/lost-password"}>Did you forget
                                                your
                                                password?</Link>
                                        </Trans>
                                    </div>
                                </div>
                                <RegisterConnect/>
                                <RegisterInstead/>
                            </form>
                            <form onSubmit={handleSubmitCode} hidden={isFirstView}>
                                <div className="Register-Content-Header">
                                    <h1 className="Register-Heading">Security verification<b/></h1>
                                    <div className="Register-Content-Body">
                                        <div className="Register-Row">
                                            <div className="Register-FormField Register-FormField--Next">
                                                <input type="text" name="code" className="Register-Input"
                                                       onChange={e => setCode(e.target.value)}
                                                       placeholder="Enter 6-digit one time SMS code"
                                                       autoComplete="code"/>
                                                <button disabled={fetching} type="submit" data-loading={fetching}
                                                        className="Button Button--Next">
                                                    <div className="Button__Loader"/>
                                                </button>
                                            </div>
                                            <span
                                                className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                                        </div>
                                        <div className="Register-Row"/>
                                        <button type="button" onClick={() => timeout <= 0 ? setTimeout(60) : ''}
                                                className="Button Button--Light"
                                                disabled={timeout > 0 || fetching}>
                                            Send SMS code
                                            {timeout > -1 ? <div style={{margin: "0px 4px 0px 8px"}}>
                                                <div style={{
                                                    opacity: 0.6,
                                                    display: "inline-flex",
                                                    justifyContent: "center",
                                                    width: "12px",
                                                    height: "12px",
                                                    textAlign: "center",
                                                    lineHeight: "8px",
                                                }}>{timeout}</div>
                                            </div> : ""}
                                        </button>
                                        <div className="Register-Row"/>
                                        <button type="button" onClick={signOut} className="Button">
                                            Switch account
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </RegisterContent>
                    <RegisterFooter/>
                </div>
        }
    }

    function renderApp() {
        return () => {
            //console.log("rendering", isLoading ? "spinner" : isUserAuthorized ? "app" : "register")
            return isLoading ? <Spinner fullHeight/> : isUserAuthorized ? <OrganizationProvider/> : <Register/>
        }
    }

    function renderAcceptInvitation() {
        return ({match}) => {
            localStorage.setItem("inv", match.params.id)
            dispatch(setPendingInvitation(match.params.id))
            return <Redirect to={"/"}/>
        }
    }

    return (
        <div className={isLoading ? "spinner-parent-overlap" : ""}>
            <Router history={history}>
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/" render={renderLogin()}/>
                        <Route exact path="/accept-invitation/:id" render={renderAcceptInvitation()}/>
                        <Route render={renderApp()}/>
                    </Switch>
                    <Analytics/>
                </ScrollToTop>
            </Router>
            {isLoading ? <Spinner fullHeight/> : ''}
        </div>
    )
}


const history = createBrowserHistory();
history.listen((location) => {
    if (window.ga) {
        window.ga('set', 'page', location.pathname + location.search);
        window.ga('send', 'pageview');
    }
})

export default connect()(withTranslation('register')(Root));
