import React, {Component} from "react";
import {connect} from "react-redux";
import {daysBetween, isMobile} from "../components/utils";
import {withTranslation} from "react-i18next";
import {filterReceivedInvoicesByCompany, payInvoices} from "../actions/receivedInvoices";
import MaterialIcon from "@material/react-material-icon";
import {geIssuedInvoices, refreshIssuedInvoices} from "../actions/issuedInvoices";
import Dialog, {DialogButton, DialogContent, DialogFooter, DialogTitle} from "@material/react-dialog";
import {updateUserOrganizationAccess} from "../actions/user";
import Table from "../components/Table";
import LoadingDialog from "../components/LoadingDialog";
import {activeOrganizationSelector} from "../reducers/organizations";
import Button from "@material/react-button";
import List, {ListItem, ListItemText} from "@material/react-list";
import TextField, {HelperText, Input} from "@material/react-text-field";
import {changeAutoPayoutSettings, getAutoPayoutSettings} from "../actions/payoutRequests";
import Checkbox from "@material/react-checkbox";

const tableColumns = [
    {
        name: "receivedInvoiceId",
        label: "ID",
        sortable: false
    },
    {
        name: "issuedDate",
        label: "Issued",
        sortable: false,
        type: "timestmap"
    },
    {
        name: "receivedDate",
        label: "Received",
        sortable: false,
        type: "timestmap"
    },
    {
        name: "dueDate",
        label: "Due date",
        sortable: false,
        type: "timestmap"
    },
    {
        name: "financingInvoiceCreatedAt",
        label: "Transaction date",
        sortable: false,
        type: "timestmap"
    },
    {
        name: "issuer.name",
        label: "Issuer",
        sortable: false,
    },
    {
        name: "receiver.name",
        label: "Customer",
        sortable: false,
    },
    {
        name: "invoiceAmount",
        label: "Total",
        sortable: false,
        type: "money"
    },
    {
        name: "_paymentStatus",
        label: "Status",
        sortable: false,
    }
]

class AppSupplierIssuedInvoices extends Component {
    state = {
        isLoadingDialog: false,
        isOpen: false,
        isSettingsOpen: false,
        autoPayoutSelection: "NONE",
        autoPayoutCustom: 2,
        selectedIndex: [],
    };

    render() {
        let {t, ordered = [], autoPayoutSettings} = this.props, {isLoadingDialog} = this.state

        return (
            <div>
                <div className="mdc-layout-grid">
                    <div className="mdc-layout-grid__inner">
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                            <div className="table-header">
                                <div className="table-header__title">
                                    <h2 className="mdc-typography--headline5 title title--border table-header__title-text">
                                        Issued invoices
                                    </h2>
                                </div>
                                <div className="table-header__filters">
                                    {/*{this.props.filterByCustomer ? (
                                        <div className="mdc-chip" onClick={e => this.filterByCompany(e)}>
                                            <MaterialIcon className="mdc-chip__icon mdc-chip__icon--leading"
                                                          role="button" icon="close"/>
                                            <span className="mdc-chip__text">{this.props.filterByCustomer.name}</span>
                                        </div>) : <span/>}*/}
                                    <Button className="mdc-button--on-primary"
                                            onClick={() => this.setState({isSettingsOpen: true})}
                                            icon={<MaterialIcon icon="settings"/>}>
                                        Payout Settings
                                    </Button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table ordered={ordered} columns={tableColumns}
                                       onAction={this._onTableAction.bind(this)}/>
                            </div>
                        </div>
                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 flex flex--justify-center">
                            <button type="button" onClick={this._loadMore.bind(this)}
                                    className="button button--unelevated"
                                    hidden={this.props.loadMoreExhausted}>{t('loadMore')}</button>
                        </div>
                    </div>
                </div>
                <Dialog onClose={this._onDialogAction} open={this.state.isOpen}
                        className="dialog--wider">
                    <DialogContent>
                        <table className="table table--light table--dialog-width">
                            <tbody>
                            <tr>
                                <td><div>Total</div></td>
                                <td align="right">{this.state.amount}</td>
                            </tr>
                            <tr>
                                <td><div>Financing</div><small>{this.state.financingPercent} %/year</small></td>
                                <td align="right">{this.state.financingFee}</td>
                            </tr>
                            <tr>
                                <td><div>Processing fee</div><small>{this.state.processingPercent} %</small></td>
                                <td align="right">{this.state.processingFee}</td>
                            </tr>
                            {this.state.insurancePercent ? <tr>
                                <td><div>Insurance fee</div><small>{this.state.insurancePercent} %</small></td>
                                <td align="right">{this.state.insuranceFee}</td>
                            </tr> : null}
                            <tr>
                                <td><div>Taxes (22%)</div></td>
                                <td align="right">{this.state.taxes}</td>
                            </tr>
                            <tr>
                                <td><b>Payout amount</b></td>
                                <td align="right"><b>{this.state.payOutAmount}</b></td>
                            </tr>
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton action='dismiss'>Cancel</DialogButton>
                        <DialogButton action='accept' isDefault>Confirm</DialogButton>
                    </DialogFooter>
                </Dialog>
                <Dialog onClose={this._onPayoutSettingsDialogAction} open={this.state.isSettingsOpen}
                        className="dialog--wider">
                    <DialogTitle>Automatic Payouts</DialogTitle>
                    <DialogContent>
                        <p>Set automatic payouts before reaching due date</p>
                    </DialogContent>
                    <List
                        singleSelection
                        checkboxList
                        selectedIndex={this.state.selectedIndex}
                        handleSelect={this.handleSelect}>
                        <ListItem>
                            <Checkbox checked={this.state.autoPayoutSelection === "INSTANT"}/>
                            <ListItemText primaryText='Instantly (when added to platform)'/>
                        </ListItem>
                        <ListItem>
                            <Checkbox checked={this.state.autoPayoutSelection === "30_DAY"}/>
                            <ListItemText primaryText='30 days before due date'/>
                        </ListItem>
                        <ListItem>
                            <Checkbox checked={this.state.autoPayoutSelection === "15_DAY"}/>
                            <ListItemText primaryText='15 days before due date'/>
                        </ListItem>
                        <ListItem>
                            <Checkbox checked={this.state.autoPayoutSelection === "CUSTOM"}/>
                            <ListItemText primaryText='Custom'/>
                        </ListItem>
                    </List>
                    <DialogContent className="dialog-textfield-dialog-content">
                        <TextField
                            className="dialog-textfield"
                            label='Custom days'
                            helperText={<HelperText>Please enter value between 2 and 60</HelperText>}>
                            <Input
                                type="number"
                                min={2}
                                max={60}
                                required={this.state.autoPayoutSelection === "CUSTOM"}
                                value={this.state.autoPayoutSelection === "CUSTOM" ? (this.state.autoPayoutCustom || 2) : ""}
                                disabled={this.state.autoPayoutSelection !== "CUSTOM"}
                                onChange={(e) => this.setState({autoPayoutCustom: e.currentTarget.value})}/>
                        </TextField>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton action='dismiss'>Cancel</DialogButton>
                        <DialogButton action='accept' isDefault>Save</DialogButton>
                    </DialogFooter>
                </Dialog>
                <LoadingDialog open={isLoadingDialog}/>
            </div>
        )
    }

    handleSelect = (activatedIndex, allSelected) => {
        let selectedIndex = this.state.selectedIndex[0] === activatedIndex ? -1 : activatedIndex, autoPayoutSelection
        switch (selectedIndex) {
            case -1:
                autoPayoutSelection = "NONE"
                break;
            case 0:
                autoPayoutSelection = "INSTANT"
                break;
            case 1:
                autoPayoutSelection = "30_DAY"
                break;
            case 2:
                autoPayoutSelection = "15_DAY"
                break;
            case 3:
                autoPayoutSelection = "CUSTOM"
                break;
        }

        this.setState({selectedIndex: selectedIndex === -1 ? [] : [selectedIndex], autoPayoutSelection})
    }

    /* handleChange = (e) => {
         this.setState({autoPayoutSelection: e.target.value});
     }*/

    _onPayoutSettingsDialogAction = (action) => {
        if (action === 'accept') {
            this.setState({isSettingsOpen: false, isLoadingDialog: true})


            if (!this.state.autoPayoutSelection) {
                this.setState({isSettingsOpen: false, isLoadingDialog: false})
                alert("There was an error submitting your request. Please reload the app and try again.")
                return
            }

            const autoPayoutSelection = this.state.autoPayoutSelection,
                autoPayoutCustom = this.state.autoPayoutCustom || 2

            this.props.dispatch(changeAutoPayoutSettings(autoPayoutSelection, autoPayoutCustom, (data, err) => {
                if (err) {
                    console.error(err)
                    alert("There was an error processing your request. Please try again later")
                } else {
                    console.log(data)
                }
                this.setState({isLoadingDialog: false})
            }))
        } else {
            this.setState({isSettingsOpen: false})
        }
    }

    _onTableAction(checkedMap) {
        // todo: ne gre financirati zapadlih računov, minimum 2 dni do dneva zapadlosti

        const invoices = [],
            today = new Date(),
            premiumHelp = window.currency(1, {symbol: '€', precision: 4}),
            {financing = null} = this.props.organization
        today.setUTCHours(0, 0, 0, 0)

        let financingPercent = 0, insurancePercent = 0, processingPercent = 0
        try {
            financingPercent = financing.financingFee.active ? financing.financingFee.percent : 0
            processingPercent = financing.processingFee.active ? financing.processingFee.percent : 0
            insurancePercent = financing.insuranceFee.active ? financing.insuranceFee.percent : 0
        } catch (e) {
            alert("Your organization is not eligible for financing")
            return;
        }

        const financingQPerDay = window.currency(financingPercent, {
            symbol: '€',
            precision: 10
        }).divide(100).divide(365)

        let amount = window.currency(0, {symbol: '€', precision: 2}),
            financingFee = window.currency(0, {symbol: '€', precision: 2})

        try {
            for (let id in checkedMap) {
                if (checkedMap.hasOwnProperty(id) && checkedMap[id] === true) {
                    invoices.push(id)
                    const invoice = this.props.invoices[id], dueDate = invoice.get("dueDate").toDate(),
                        days = daysBetween(today, dueDate),
                        invoiceAmount = invoice.get("invoiceAmount"),
                        paidValue = invoice.get("paidValue"),
                        payoutAmount = window.currency(invoiceAmount - (paidValue || 0), {symbol: '€', precision: 2});

                    if(days < 2) {
                        alert("Financing is only available for invoices with minimum of two days before due date")
                        return
                    }

                    const premiumForPeriod = financingQPerDay.multiply(days < 2 ? 30 : days)

                    financingFee = financingFee.add(payoutAmount.multiply(premiumForPeriod))
                    amount = amount.add(payoutAmount)
                }
            }
        } catch (e) {
            console.error(e)
            alert("Something went wrong")
            return
        }

        const processingPAmount = amount.multiply(premiumHelp.subtract(window.currency(processingPercent, {
                symbol: '€',
                precision: 4
            }).divide(100))),
            insurancePAmount = amount.multiply(premiumHelp.subtract(window.currency(insurancePercent, {
                symbol: '€',
                precision: 4
            }).divide(100))),
            processingFee = amount.subtract(processingPAmount),
            processingFeeTotal = processingFee.multiply(1.22), // add 22% VAT
            taxes = processingFeeTotal.subtract(processingFee),
            insuranceFee = amount.subtract(insurancePAmount),
            payOutAmount = amount.subtract(financingFee).subtract(processingFee).subtract(insuranceFee).subtract(taxes)

        this.setState({
            isOpen: true,
            payOutAmount: payOutAmount.format(),
            financingFee: financingFee.format(),
            processingFee: processingFee.format(),
            insuranceFee: insuranceFee.format(),
            taxes: taxes.format(),
            amount: amount.format(),
            financingPercent, insurancePercent, processingPercent,
            invoices
        })
    }

    _onDialogAction = action => {
        if (action === 'accept') {
            this.setState({isOpen: false, isLoadingDialog: true})
            console.log("calling server with", this.state.invoices);
            this.props.dispatch(payInvoices(this.state.invoices, () => {
                this.props.dispatch(refreshIssuedInvoices())
                this.setState({isLoadingDialog: false})
            }))
        } else {
            this.setState({isOpen: false})
        }
    }

    filterByCompany(e, id, name) {
        e.preventDefault();
        this.props.dispatch(filterReceivedInvoicesByCompany(id ? {id, name} : null))
    }

    _loadMore() {
        this.props.dispatch(geIssuedInvoices(true))
    }

    onRowClick(id) {
        if (isMobile()) {
            this.setState({redirect: id});
        }
    }

    componentDidMount() {
        this.props.dispatch(geIssuedInvoices(false, true))
        this.props.dispatch(getAutoPayoutSettings())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.permissionError && prevProps.permissionError !== this.props.permissionError) {
            this.props.dispatch(updateUserOrganizationAccess(() => {
                this.props.dispatch(geIssuedInvoices(false, true))
                this.props.dispatch(getAutoPayoutSettings())
            }))
        }
        if (prevProps.autoPayoutSettings !== this.props.autoPayoutSettings && this.props.autoPayoutSettings) {
            this.setState({
                autoPayoutCustom: this.props.autoPayoutSettings.type === "CUSTOM" ? this.props.autoPayoutSettings.beforeDueDateDays : "",
                autoPayoutSelection: this.props.autoPayoutSettings.type
            })
        }
    }
}

const mapStateToProps = state => {
    return {
        firebase: state.firebase,
        organization: activeOrganizationSelector(state),
        filterByCustomer: state.issuedInvoices.filterByCustomer,
        loadMoreExhausted: state.issuedInvoices.loadMoreExhausted,
        invoices: state.issuedInvoices,
        ordered: state.issuedInvoices.ordered,
        permissionError: state.issuedInvoices.permissionError,
        autoPayoutSettings: state.payoutRequests.autoPayoutSettings,
    };
};

export default connect(mapStateToProps)(withTranslation("app")(AppSupplierIssuedInvoices));
