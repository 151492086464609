import * as storage from 'redux-storage'
//import createEngine from 'redux-storage-engine-localstorage';
import {composeWithDevTools} from 'redux-devtools-extension';
import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from 'redux-thunk';
import root from "./reducers/root";
import user from "./reducers/user";
import tabs from "./reducers/tabs";
import notifications from "./reducers/notifications";
import dialog from "./reducers/dialog";
import {firebaseReducer} from "react-redux-firebase";
import {firestoreReducer} from "redux-firestore";
import receivedInvoices from "./reducers/receivedInvoices";
import issuedInvoices from "./reducers/issuedInvoices";
import organizations from "./reducers/organizations";
import payoutRequests from "./reducers/payoutRequests";
import billing from "./reducers/billing";
import invoiceIssuers from "./reducers/invoiceIssuers";
import loader from './store/loader';
import invitation from './store/invitation';
import agreements from './store/agreements';

const reducer = storage.reducer(combineReducers({
    root,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    user,
    loader,
    invitation,
    agreements,
    organizations,
    receivedInvoices,
    issuedInvoices,
    payoutRequests,
    tabs,
    notifications,
    invoiceIssuers,
    dialog,
    billing
}));


//const engine = createEngine('my-save-key');

//const middleware = storage.createMiddleware(engine);


//const createStoreWithMiddleware = applyMiddleware(middleware)(createStore);
const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

/*const load = storage.createLoader(engine);
load(store)
    .then((newState) => console.log('Loaded state:', newState))
    .catch(() => console.log('Failed to load previous state'));*/

export default store;
