import React, {Component} from "react";
import {Trans, withTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";
import {API_URL} from "../config";
import firebase, {functions} from "./../firebase";

class AppLostPassword extends Component {
    state = {
        email: ''
    };

    render() {
        //const {user} = this.props, {editingTaxResidence, editingTaxNo, editingIncome} = this.state;
        const {t} = this.props, {fetching, error, redirect} = this.state;
        if (redirect) return <Redirect to="/sent-confirmation"/>;
        return (
            <form onSubmit={this._handleSubmit.bind(this)}>
                <div className="Register-Content-Header">
                    <Trans i18nKey='resetPasswordTitle'>
                        <h1 className="Register-Heading">{t('resetPasswordTitle')}</h1>
                    </Trans>
                    <Trans i18nKey='resetPasswordSubtitle'>
                        <span className="Register-Subtitle">{t('resetPasswordText')}</span>
                    </Trans>
                    <div className="Register-Content-Body">
                        <div className="Register-Row">
                            <div className="Register-FormField Register-FormField--Next">
                                <input ref="email" type="email" name="email" className="Register-Input"
                                       value={this.state.email}
                                       onChange={e => this.setState({email: e.target.value})}
                                       placeholder={t('yourEmail')} required
                                       autoComplete="email"/>
                                <button disabled={fetching} type="submit" data-loading={fetching}
                                        className="Button Button--Next">
                                    <div className="Button__Loader"/>
                                </button>
                            </div>
                        </div>
                        <div className="Register-Row">
                            <span className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                        </div>
                        {/*<Trans i18nKey='resetPasswordHere'>
                            <span className="Register-Subtitle">Lost password? <Link className="link" to={"/recover"}>Reset password</Link></span>
                        </Trans>*/}
                    </div>
                </div>
            </form>
        )
    }

    _handleSubmit(e) {
        e.preventDefault();

        const email = this.state.email;

        firebase.auth().sendPasswordResetEmail(email).then(() => {
            this.setState({error: '', redirect: true, fetching: false});
        }).catch(err => {
            console.error(err)
            this.setState({error: "There was an error submitting your request", fetching: false});
        })

    }
}

export default withTranslation('app')(AppLostPassword);
