import React, {useEffect, useRef, useState} from 'react';

import '../styles/app.scss';
import {ReactComponent as UserIcon} from "../images/user-icon.svg";
import {ReactComponent as NotificationsIcon} from "../images/outline-notifications-24px.svg";
import {ReactComponent as LogOutIcon} from "../images/exit_to_app-black-24dp.svg";
import ScrollToTop from "../components/ScrollToTop";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import AppAccount from "./AppAccount";

import {useDispatch, useSelector} from "react-redux";
import {withTranslation} from "react-i18next";
import AppSettings from "./AppSettings";
import AppBuyerReceivedInvoices from "./AppBuyerReceivedInvoices";
import AppSupplierIssuedInvoices from "./AppSupplierIssuedInvoices";
import {activeOrganizationSelector} from "../reducers/organizations";
import MaterialIcon from "@material/react-material-icon";
import AppPaymentRequests from "./AppPaymentRequests";
import AppAdminOrganizations from "./AppAdminOrganizations";
import {listenForNotifications} from "../actions/notifications";
import AppBuyerSellers from "./AppBuyerSellers";
import AcceptInvitation from "../components/AcceptInvitation";
import {pendingInvitation} from "../store/invitation";
import firebase from "../firebase";
import Menu, {MenuList} from "@material/react-menu";
import PlatformAgreements from "../components/PlatformAgreements";

// TODO:
const mapStateToProps = state => {
    return {
        _lang: state.user.currentUser && state.user.currentUser.settings ? state.user.currentUser.settings.language : "EN",
        _currentUser: state.user.currentUser,
        _profile: state.firebase.profile,
        _isAdmin: state.firebase.profile.admin === true,
        _redirectOnLoginUri: state.user.redirectOnLoginUri,
        _notifications: state.notifications.ordered,
        _organizationId: state.firebase.profile.organizationId,
        _organization: activeOrganizationSelector(state),
        _pendingInvitation: pendingInvitation(state),
    };
};

const App = props => {
    const [redirect, setRedirect] = useState()

    const _notifications = useSelector(state => state.notifications.ordered)
    const _organization = useSelector(activeOrganizationSelector)
    const _profile = useSelector(state => state.firebase.profile)
    const _isAdmin = useSelector(state => state.firebase.profile.admin === true)
    const _organizationId = useSelector(state => state.firebase.profile.organizationId)


    const dispatch = useDispatch()

    const {t} = props;

    useEffect(() => {
        dispatch(listenForNotifications())
    }, [dispatch])

    /*useEffect(() => {
        if(agreements) {
            // check if user has agreed to the latest agreements
            if(agreements.termsOfService !== LATEST_TERMS_OF_SERVICE_VERSION) {
                setIsAgreementDialogOpen(true)
            } else if(false) {

            }
            console.log(agreements)
        }
    }, [agreements])*/

    const notificationsMenuButton = useRef()
    const [openNotificationsMenu, setOpenNotificationsMenu] = useState(false)
    const [notificationsMenuCoordinates, setNotificationsMenuCoordinates] = useState()

    useEffect(() => {
        if (notificationsMenuButton) {
            const rect = notificationsMenuButton.current.getBoundingClientRect();
            setNotificationsMenuCoordinates({x: rect.right - 240, y: rect.bottom})
        }

    }, [notificationsMenuButton])

    const signOut = () => {
        firebase.auth().signOut().finally(() => window.location.reload())
    }

    return <div>
        {redirect && (<Redirect to={redirect}/>)}
        <div className="app mdc-typography">
            <main className="app__main-wrapper">
                <aside className="app__main-sidebar">
                    {/*<div className="maximize-wrapper">
                                <button
                                    className="icon-button icon-button--primary icon-button--dense icon-button--right"
                                    ref="maximize">
                                    <HamburgerIcon/>
                                </button>
                            </div>*/}
                    <div className="mdc-list mdc-list--nav mdc-list--non-interactive">
                        {_organization.accessGranted ? _organization.provider ? (
                            <div>
                                <NavLink to="/u/dashboard" className="mdc-list-item"
                                         activeClassName="mdc-list-item--active">
                                    <MaterialIcon className="mdc-list-item__graphic icon-button" icon="inbox"/>
                                    <span className="mdc-list-item__text">Received invoices</span>
                                </NavLink>
                                <NavLink to="/u/suppliers" className="mdc-list-item"
                                         activeClassName="mdc-list-item--active">
                                    <MaterialIcon className="mdc-list-item__graphic icon-button"
                                                  icon="business"/>
                                    <span className="mdc-list-item__text">Suppliers</span>
                                </NavLink>
                            </div>
                        ) : _organizationId ? (
                            <NavLink to="/u/dashboard" className="mdc-list-item"
                                     activeClassName="mdc-list-item--active">
                                <MaterialIcon className="mdc-list-item__graphic icon-button" icon="inbox"/>
                                <span className="mdc-list-item__text">Issued invoices</span>
                            </NavLink>
                        ) : '' : ''}
                        {_isAdmin ? (
                            <NavLink to="/u/financing-requests" className="mdc-list-item"
                                     activeClassName="mdc-list-item--active">
                                <MaterialIcon className="mdc-list-item__graphic icon-button" icon="receipt"/>
                                <span className="mdc-list-item__text">Financing requests</span>
                            </NavLink>
                        ) : ''}
                        {_isAdmin ? (
                            <NavLink to="/u/organizations" className="mdc-list-item"
                                     activeClassName="mdc-list-item--active">
                                <MaterialIcon className="mdc-list-item__graphic icon-button" icon="business"/>
                                <span className="mdc-list-item__text">Organizations</span>
                            </NavLink>
                        ) : ''}
                        {_organizationId ? (
                            <NavLink to="/u/settings" className="mdc-list-item"
                                     activeClassName="mdc-list-item--active">
                                <MaterialIcon className="mdc-list-item__graphic icon-button" icon="settings"/>
                                <span className="mdc-list-item__text">Settings</span>
                            </NavLink>
                        ) : ''}
                        <NavLink to="/u/account" className="mdc-list-item"
                                 activeClassName="mdc-list-item--active">
                            <span className="mdc-list-item__graphic icon-button"><UserIcon/></span>
                            <span className="mdc-list-item__text">Account</span>
                        </NavLink>
                    </div>
                    <div className="mdc-layout-grid mdc-layout-grid-logo-container">
                        <div className="logoWrapper"/>
                    </div>
                </aside>
                <div className="app__main">
                    <header className="app__main-header">
                                <span className="mdc-typography--body2 hide-on-mobile">{t('welcomeBackName')} <b
                                    className="app__main-header-name">{_profile.firstName}</b></span>
                        <div className="app__main-header__logo show-on-mobile"/>
                        <div className="app__main-header__actions">
                            <div className="mdc-menu-surface--anchor">
                                <button ref={notificationsMenuButton} onClick={() => setOpenNotificationsMenu(true)}
                                        className="icon-button icon-button--dense">
                                    <NotificationsIcon/>
                                </button>
                                <Menu
                                    className="notifications-menu"
                                    open={openNotificationsMenu}
                                    onClose={() => setOpenNotificationsMenu(false)}
                                    coordinates={notificationsMenuCoordinates}>
                                    <MenuList>
                                        <h4 className="mdc-menu__title">{t('notifications')}</h4>
                                        <ul className={"mdc-list" + ((_notifications || []).length > 0 ? ' mdc-list--two-line' : '')}
                                            role="menu" aria-hidden="true"
                                            aria-orientation="vertical" tabIndex="-1">
                                            <li className="mdc-list-divider"/>
                                            {(_notifications || []).map((item, index) => (
                                                <li key={"notification-" + index} className="mdc-list-item"
                                                    role="menuitem">
                                                        <span className="mdc-list-item__text">
                                                          <span
                                                              className="mdc-list-item__primary-text">{item.get("message")}</span>
                                                          <span
                                                              className="mdc-list-item__secondary-text">{item.get("params.orgName")}</span>
                                                        </span>
                                                </li>
                                            ))}
                                            {(_notifications || []).length === 0 && <li key={"notification"}
                                                                                        className="mdc-list-item mdc-list-item--disabled">
                                                        <span className="mdc-list-item__text">
                                                          <span
                                                              className="mdc-list-item__secondary-text">{t('noNotifications')}</span>
                                                        </span>
                                            </li>}
                                        </ul>
                                    </MenuList>
                                </Menu>
                            </div>
                            <button onClick={signOut} className="icon-button icon-button--dense">
                                <LogOutIcon/></button>
                        </div>
                    </header>
                    <div className="app__main-content">
                        <ScrollToTop>
                            {_organizationId ? (
                                <Switch>
                                    <Route exact path="/" render={() => (
                                        <Redirect to="/u/dashboard"/>
                                    )}/>
                                    <Route exact path="/u" render={() => (
                                        <Redirect to="/u/dashboard"/>
                                    )}/>
                                    <Route exact path="/u/" render={() => (
                                        <Redirect to="/u/dashboard"/>
                                    )}/>
                                    <Route exact path="/u/dashboard"
                                           render={({match}) => {
                                               return _organization.accessGranted ? (_organization.provider ?
                                                   <AppBuyerReceivedInvoices match={match}/> :
                                                   <AppSupplierIssuedInvoices/>) :
                                                   <Redirect to="/u/settings"/>
                                           }}/>
                                    {_isAdmin ? <Route exact path="/u/financing-requests"
                                                        component={AppPaymentRequests}/> : null}
                                    {_isAdmin ?
                                    <Route exact path="/u/organizations" component={AppAdminOrganizations}/> : null}

                                    <Route exact path="/u/suppliers" component={AppBuyerSellers}/>
                                    <Route exact path="/u/suppliers/:id" component={AppBuyerReceivedInvoices}/>

                                    <Route exact path="/u/settings" component={AppSettings}/>
                                    <Route exact path="/u/account" component={AppAccount}/>
                                    <Redirect to="/u/dashboard"/>
                                </Switch>
                            ) : (
                                <Switch>
                                    <Route exact path="/" render={() => (
                                        <Redirect to="/u/account"/>
                                    )}/>
                                    <Route exact path="/u" render={() => (
                                        <Redirect to="/u/account"/>
                                    )}/>
                                    <Route exact path="/u/" render={() => (
                                        <Redirect to="/u/account"/>
                                    )}/>
                                    {_isAdmin ? (
                                        <Route exact path="/u/financing-requests"
                                               component={AppPaymentRequests}/>
                                    ) : ''}
                                    {_isAdmin ? (
                                        <Route exact path="/u/organizations" component={AppAdminOrganizations}/>
                                    ) : ''}
                                    <Route exact path="/u/account" component={AppAccount}/>
                                    <Redirect to="/u/account"/>
                                </Switch>
                            )}
                        </ScrollToTop>
                    </div>
                </div>
            </main>
            <footer className="app__footer">
                <div className="app__footer-connect mdc-layout-grid mdc-layout-grid--align-left"/>
                <div className="mdc-typography--caption app__footer-links">
                    <span>© 2021. All rights reserved. Nekster Finance, d.o.o.</span>
                    {/*<span>Update cookie settings</span>*/}
                    {/*<span>Privacy policy</span>*/}
                    {/*<span>Imprint</span>*/}
                </div>
            </footer>
        </div>
        {props._pendingInvitation ? <AcceptInvitation id={props._pendingInvitation}/> : <PlatformAgreements/>}
    </div>
}

export default withTranslation("app")(App);
