import React, {Component} from "react";
import {connect} from "react-redux";
import {isMobile} from "../components/utils";
import {withTranslation} from "react-i18next";
import Table from "../components/Table";
import {getInvoiceIssuers} from "../actions/invoiceIssuers";
import {Redirect} from "react-router-dom";

const tableColumns = [
    {
        name: "vatNumber",
        label: "VAT No",
        sortable: false
    },
    {
        name: "name",
        label: "Name",
        sortable: false,
        /*type: "timestmap"*/
    },
    {
        name: "address.address",
        label: "Address",
        sortable: false,
        /*type: "timestmap"*/
    },
    {
        name: "address.city",
        label: "City",
        sortable: false,
        /*type: "timestmap"*/
    },
    {
        name: "address.postalCode",
        label: "Postal code",
        sortable: false,
       /* type: "timestmap"*/
    },
    /*{
        name: "issuer.name",
        label: "Issuer",
        sortable: false,
    },
    {
        name: "invoiceAmount",
        label: "Total",
        sortable: false,
        type: "money"
    },
    {
        name: "_paymentStatus",
        label: "Status",
        sortable: false,
    }*/
]

class AppBuyerSellers extends Component {
    state = {
        redirectToChild: null
    };

    render() {
        let {t, ordered = []} = this.props, {redirectToChild} = this.state
        if(redirectToChild) return <Redirect push to={"/u/suppliers/" + redirectToChild}/>
        return (
            <div>
                <div className="mdc-layout-grid">
                    <div className="mdc-layout-grid__inner">
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                            <div className="table-header">
                                <div className="table-header__title">
                                    <h2 className="mdc-typography--headline5 title title--border table-header__title-text">
                                        Suppliers
                                    </h2>
                                </div>
                                <div className="table-header__filters">

                                </div>
                            </div>

                            <div className="table-responsive">
                                <Table ordered={ordered} columns={tableColumns} onAction={this._onTableRowAction} disableSelection/>
                            </div>
                        </div>
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 flex flex--justify-center">
                            <button type="button" onClick={this._loadMore.bind(this)}
                                    className="button button--unelevated"
                                    hidden={this.props.loadMoreExhausted}>{t('loadMore')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    _onTableRowAction = ({id}) => {
        const rowData = this.props.invoices[id]
        if(rowData) {
            this.setState({redirectToChild: rowData.get("companyId")})
            console.log(id)
        }

    }

    /*filterByCompany(e, id, name) {
        e.preventDefault();
        this.props.dispatch(filterReceivedInvoicesByCompany(id ? {id, name} : null))
    }*/

    _loadMore() {
        this.props.dispatch(getInvoiceIssuers(true));
    }

    onRowClick(id) {
        if (isMobile()) {
            this.setState({redirect: id});
        }
    }

    componentDidMount() {
        this.props.dispatch(getInvoiceIssuers(null, true))
    }
}

const mapStateToProps = state => {
    return {
        firebase: state.firebase,
        filterByCustomer: state.invoiceIssuers.filterByCustomer,
        loadMoreExhausted: state.invoiceIssuers.loadMoreExhausted,
        invoices: state.invoiceIssuers,
        ordered: state.invoiceIssuers.ordered
    };
};

export default connect(mapStateToProps)(withTranslation("app")(AppBuyerSellers));
