import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";


class InactiveOrganization extends Component {
    render() {
        const {t} = this.props;
        return (
            <div className={this.props.light ? 'banner banner--custom-light' : 'banner'}>
                <div className="banner__row">
                    <h3 className="mdc-typography--headline6 banner__title">Pending account confirmation</h3>
                    <span className="mdc-typography--body1 banner__content">Please wait until we confirm your account.</span>
                    {/*<Link to="/u/auto-invest-strategies" className="button button--action">{t('aiBannerCTA')}</Link>*/}
                </div>
            </div>
        );
    }

}

export default withTranslation("app")(InactiveOrganization);
