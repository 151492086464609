import React, {Component} from 'react';
import Dialog, {DialogContent} from "@material/react-dialog";

class LoadingDialog extends Component {

    render() {
        const {open} = this.props

        return (
            <Dialog open={open} escapeKeyAction="" scrimClickAction="">
                <DialogContent>
                    <div role="button" className="spinner-wrapper spinner-wrapper--tall" tabIndex="0"><span className="spinner"/></div>
                </DialogContent>
            </Dialog>
        );
    }


}

export default LoadingDialog;
