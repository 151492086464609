import {
    FILTER_ISSUED_INVOICES_BY_COMPANY,
    GET_ISSUED_INVOICES,
    GET_ISSUED_INVOICES_PERMISSION_ERROR
} from '../actions/issuedInvoices';

const INITIAL_STATE = {
    ordered: [],
    filterByCustomer: null, // filter
    limit: 25,
    loadMoreExhausted: false,
    updated: null,
    permissionError: false
};

const issuedInvoices = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ISSUED_INVOICES:
            return {
                ...state,
                ...action.dataMap,
                ordered: action.replace ? [...(action.ordered || [])] : [...state.ordered, ...(action.ordered || [])],
                loadMoreExhausted: action.loadMoreExhausted,
                updated: action.updated,
                permissionError: false
            };
        case GET_ISSUED_INVOICES_PERMISSION_ERROR:
            return {
                ...state,
                permissionError: true
            };
        case FILTER_ISSUED_INVOICES_BY_COMPANY:
            return {
                ...state,
                filterByCustomer: action.customer,
                ordered: [],
                loadMoreExhausted: false,
                permissionError: false
            };
        default:
            return state;
    }
};

export default issuedInvoices;
