import {createSlice} from '@reduxjs/toolkit';
import {functions} from "../firebase";

const initialState = {
    termsOfService: "",
    privacyPolicy: "",
    processingChange: false
};

export const slice = createSlice({
    name: 'agreements',
    initialState,
    reducers: {
        setPlatformAgreementVersions: (state, action) => ({
            termsOfService: action.payload.termsOfService || "",
            privacyPolicy: action.payload.privacyPolicy || "",
        }),
        setProcessingChange: (state, action) => ({processingChange: action.payload}),
    }
});

// ACTIONS

const {setPlatformAgreementVersions, setProcessingChange} = slice.actions;

export const fetchPlatformAgreementVersions = () => async (dispatch) => {
    const getLatestAgreementVersion = functions.httpsCallable("getLatestAgreementVersion")
    getLatestAgreementVersion().then(res => {
        if (res && res.data) {
            dispatch(setPlatformAgreementVersions(res.data))
        }
    }).catch(err => {
        console.error(err)
    })
}

export const acceptPlatformAgreement = (type, agreementVersion) => async (dispatch) => {
    dispatch(setProcessingChange(true))
    const acceptLatestAgreement = functions.httpsCallable("acceptLatestAgreement")
    acceptLatestAgreement({[type]: agreementVersion}).then(res => {
        if (res && res.data && res.data.success) {

        }
    }).catch(err => {
        console.error(err)
    }).finally(() => dispatch(setProcessingChange(false)))
}

// SELECTORS

export const platformAgreementVersions = state => state.agreements;
export const platformAgreementProcessing = state => state.agreements.processingChange;

export default slice.reducer;
