import {FILTER_RECEIVED_INVOICES_BY_COMPANY, GET_RECEIVED_INVOICES, SET_PROCESSING} from '../actions/receivedInvoices';

const INITIAL_STATE = {
    ordered: [],
    filterByCustomer: null, // filter
    limit: 25,
    loadMoreExhausted: false,
    updated: null,
    processing: false,
};

const receivedInvoices = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_RECEIVED_INVOICES:
            return {
                ...state,
                ...action.dataMap,
                ordered: action.replace ? [...(action.ordered || [])] : [...state.ordered, ...(action.ordered || [])],
                loadMoreExhausted: action.loadMoreExhausted,
                updated: action.updated,
            };
        case FILTER_RECEIVED_INVOICES_BY_COMPANY:
            return {
                ...state,
                filterByCustomer: action.customer,
                ordered: [],
                loadMoreExhausted: false,
            };
        case SET_PROCESSING:
            return {
                ...state,
                processing: true,
            };
        default:
            return state;
    }
};

export default receivedInvoices;
