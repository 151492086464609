export const formatMoney = (amount, decimalCount = 2, decimal = ",", thousands = ".") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

export const formatDate = ts => {
    const d = new Date(ts)
    const ye = new Intl.DateTimeFormat('sl', {year: 'numeric'}).format(d);
    const mo = new Intl.DateTimeFormat('sl', {month: '2-digit'}).format(d);
    const da = new Intl.DateTimeFormat('sl', {day: '2-digit'}).format(d);
    return `${da}${mo}.${ye}`
};

/*export const formatDate = (millis) => {
   const date = new Date(millis);
   return date.toLocaleDateString()
};*/

export const isMobile = () => {
    return window.outerWidth <= 479
};

export const treatAsUTC = (date) => {
    const result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}

export const daysBetween = (startDate, endDate) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

