import firebase from 'firebase/app'
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || window.location.host === "testscf.nekster.com"

const firebaseConfig = isDev ? {
    databaseURL: "https://test-scf-ff293.firebaseio.com",
    apiKey: "AIzaSyBLjwnfGAJN4HQvYDRuR43ker-gW28SG2Y",
    authDomain: "test-scf-ff293.firebaseapp.com",
    projectId: "test-scf-ff293",
    storageBucket: "test-scf-ff293.appspot.com",
    messagingSenderId: "302553170118",
    appId: "1:302553170118:web:ab2557e5df1f524f4d97c7"
} : {
    databaseURL: "https://supply-chain-financing.firebaseio.com",
    apiKey: "AIzaSyCoEalJBt_eRYkh999dB0C4myek8pU6TDI",
    authDomain: "supply-chain-financing.firebaseapp.com",
    projectId: "supply-chain-financing",
    storageBucket: "supply-chain-financing.appspot.com",
    messagingSenderId: "75211028521",
    appId: "1:75211028521:web:cc45e726e4c32aa4870438"
};

// Initialize firebase instance
const firebaseApp = firebase.initializeApp(firebaseConfig)
// Initialize other services on firebase instance
export const firestore = firebase.firestore() // <- needed if using firestore
export const auth = firebase.auth() // <- needed if using firestore
export const functions = firebaseApp.functions("europe-west1") // <- needed if using httpsCallable

export default firebase
