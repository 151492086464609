import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {activeOrganizationSelector} from "../reducers/organizations";
import InactiveOrganization from "../components/InactiveOrganization";
import IconButton from "@material/react-icon-button";
import MaterialIcon from "@material/react-material-icon";
import Dialog, {DialogButton, DialogContent, DialogFooter, DialogTitle} from "@material/react-dialog";
import Select, {Option} from "@material/react-select";
import Button from "@material/react-button";
import {
    getCurrentOrganization,
    getOrganizationIBANList,
    getOrganizationUsers,
    inviteMemberToOrganization,
    updateOrganizationIBAN,
    updateOrganizationUserRole
} from "../actions/organizations";
import PhoneCodeDialog from "../components/PhoneCodeDialog";
import TextField, {HelperText, Input} from "@material/react-text-field";
import LoadingDialog from "../components/LoadingDialog";
import {getBillingHistory} from "../actions/billing";
import Spinner from "../components/Spinner";
import {formatDate} from "../components/utils";
import {updateUserBillingEmail} from "../actions/user";
import {Snackbar} from "@material/react-snackbar";

const mapStateToProps = state => {
    return {
        _organization: activeOrganizationSelector(state),
        user: state.firebase.auth,
        usersAccess: state.organizations.usersAccess,
        userAccessError: state.organizations.userAccessError,
        billing: state.billing,
        _activeTab: state.tabs.tab,
        _role: state.firebase.profile?.role,
    };
};

class AppSettings extends Component {
    state = {
        isIBANDialogOpen: false,
        isBillingHistoryDialogOpen: false,
        isAddMemberDialogOpen: false,
        isEditMemberDialogOpen: false,
        isPhoneCodeDialogOpen: false,
        isEditBillingEmailDialogOpen: false,
        isLoadingDialog: false,
        action: '',
        selectedBankAccount: '',
        verifiedId: null,
        snackOpen: false,
    };

    render() {
        const {user, t, _organization, usersAccess, userAccessError, billing} = this.props, {
            isIBANDialogOpen,
            isBillingHistoryDialogOpen,
            bankAccounts,
            isPhoneCodeDialogOpen,
            isAddMemberDialogOpen,
            isEditMemberDialogOpen,
            isLoadingDialog,
            isEditBillingEmailDialogOpen
        } = this.state;
        const {IBAN, unconfirmedIBAN, status} = _organization.bankAccount || {IBAN: null}
        return (
            <div>
                {!_organization.accessGranted ? (<InactiveOrganization/>) : ''}
                <div className="mdc-layout-grid">
                    <div className="mdc-layout-grid__inner">
                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet">
                            <h2 className="mdc-typography--headline5 title title--border">Your organization</h2>
                            <i className="title-spacer"/>
                            <div className="list list--vertical list--ordinal-background">
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">Organization ID</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{_organization.id}</b></span>
                                </div>
                                <div className="list-item">
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item">{t('organizationName')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{_organization.name}</b></span>
                                </div>
                                <div className="list-item">
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item">{t('address')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{_organization.address.address}, {_organization.address.city}, {_organization.address.country}</b></span>
                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">VAT number</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{_organization.vatNum}</b></span>
                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">Bank account IBAN</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{status === "pending" ? ("PENDING REVIEW (" + unconfirmedIBAN + ")") : IBAN}</b></span>
                                    {status !== "pending" ? (<IconButton className="icon-button--dense"
                                                                         onClick={this._openIBANListDialog.bind(this)}><MaterialIcon
                                        icon='edit'/></IconButton>) : ''}

                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">Billing email</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{_organization.billingEmail}</b></span>
                                    <IconButton className="icon-button--dense"
                                                onClick={() => this.setState({isEditBillingEmailDialogOpen: true})}><MaterialIcon
                                        icon='edit'/></IconButton>
                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">Billing history</span>
                                    <Button onClick={this._viewBillingHistory.bind(this)} dense unelevated>View billing
                                        history</Button>
                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">Contact person</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{_organization.contactPerson.firstName} {_organization.contactPerson.lastName}</b></span>
                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">Contact phone number</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{_organization.contactPerson.phone}</b></span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet">
                            <h2 className="mdc-typography--headline5 title title--border">Connections</h2>
                            <i className="title-spacer"/>
                            <div className="list list--vertical list--ordinal-background">
                                <div className="list-item">
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item">Connection provider</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{_organization.provider || "/"}</b></span>
                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">Provider connection ID</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{_organization.providerConnectedId || "/"}</b></span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet">
                            {usersAccess ? (
                                <div>
                                    <div className="title-action-wrapper">
                                        <h2 className="mdc-typography--headline5 title title--border">Access
                                            Management</h2>
                                        <Button onClick={() => this.setState({isAddMemberDialogOpen: true})} unelevated
                                                className="title-action" icon={<MaterialIcon icon="add"/>}>Add</Button>
                                    </div>
                                    <i className="title-spacer"/>
                                    <div className="list list--vertical list--ordinal-background">
                                        {usersAccess ? usersAccess.map(item => (
                                            <div key={item.id} className="list-item list-item--fixed">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{item.name} ({item.email})</span>
                                                <span
                                                    className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{item.role}</b></span>
                                                <IconButton className="icon-button--dense"
                                                            onClick={() => this._openEditMemberDialog(item)}><MaterialIcon
                                                    icon='edit'/></IconButton>
                                            </div>)) : (
                                            <div className="spinner-wrapper"><span className="spinner"/></div>)}
                                    </div>
                                </div>
                            ) : userAccessError ? '' : (
                                <div className="spinner-wrapper"><span className="spinner"/></div>)}
                        </div>
                    </div>
                </div>
                <Dialog
                    onClose={this._onBankAccountSelected.bind(this)}
                    open={isIBANDialogOpen}>
                    <DialogTitle>Select Bank Account</DialogTitle>
                    <DialogContent>
                        {Array.isArray(bankAccounts) ? (
                            <div>
                                <Select className="mdc-select--default"
                                        onChange={(evt) => this.setState({selectedBankAccount: evt.target.value})}
                                        value={this.state.selectedBankAccount} required
                                        outlined>
                                    <Option value={""}>-- Select --</Option>
                                    {bankAccounts.map(({number}) => (
                                        <Option key={number} value={number}>{number}</Option>))}
                                </Select>
                            </div>
                        ) : (
                            <div className="spinner-wrapper"><span className="spinner"/></div>
                        )}
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton action='dismiss'>Cancel</DialogButton>
                        <DialogButton action='accept' isDefault disabled={!this.state.selectedBankAccount}>Select</DialogButton>
                    </DialogFooter>
                </Dialog>
                <Dialog
                    onClose={() => this.setState({isBillingHistoryDialogOpen: false})}
                    open={isBillingHistoryDialogOpen}
                    className="dialog--wide">
                    <DialogTitle>Billing History</DialogTitle>
                    <DialogContent>
                        {!billing.updated && !billing.error ? <Spinner/> : (
                            <table className="table table--light table--dialog-width">
                                <thead>
                                <tr>
                                    <th>Issued date</th>
                                    <th>Document type</th>
                                    <th>Document number</th>
                                    <th>Invoice amount</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {billing.ordered && billing.ordered.length > 0 ? billing.ordered.map(item => (
                                    <tr key={item.id}>
                                        <td>{formatDate(item.createdAt.toDate())}</td>
                                        <td>Invoice</td>
                                        <td>123456789</td>
                                        <td>{item.total.formatted}</td>
                                        <td align="right"><Button dense href="/">Download</Button></td>
                                    </tr>
                                )) : (
                                    <tr key="emptybilling">
                                        <td>No results</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        )}
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton action='dismiss'>Close</DialogButton>
                    </DialogFooter>
                </Dialog>
                <Dialog
                    onClose={this._sendUserInvitationDialog.bind(this)}
                    open={isAddMemberDialogOpen}>
                    <DialogTitle>Add Member</DialogTitle>
                    <DialogContent>
                        <TextField
                            label='Email'
                            helperText={<HelperText>Enter user email</HelperText>}
                        ><Input
                            value={this.state.addMemberEmail}
                            onChange={(e) => this.setState({addMemberEmail: e.currentTarget.value})}/>
                        </TextField>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton className="button--dismiss" action='dismiss'>Close</DialogButton>
                        <DialogButton action='accept' isDefault>Send Invitation</DialogButton>
                    </DialogFooter>
                </Dialog>
                <Dialog
                    onClose={this._editBillingEmail}
                    open={isEditBillingEmailDialogOpen}>
                    <DialogTitle>Edit Billing Email</DialogTitle>
                    <DialogContent>
                        <TextField
                            label='Email'
                            helperText={<HelperText>Enter new billing email</HelperText>}
                        ><Input
                            value={this.state.billingEmail}
                            onChange={(e) => this.setState({billingEmail: e.currentTarget.value})}/>
                        </TextField>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton className="button--dismiss" action='dismiss'>Close</DialogButton>
                        <DialogButton action='accept' isDefault>Save</DialogButton>
                    </DialogFooter>
                </Dialog>
                <Dialog
                    onClose={this._editMemberDialog.bind(this)}
                    open={isEditMemberDialogOpen} className="dialog--wider">
                    <DialogTitle>Edit {this.state.editMemberEmail}</DialogTitle>
                    <DialogContent>
                        <Select className="mdc-select--default full-width" disabled={isLoadingDialog}
                                onChange={(evt) => this.setState({editMemberRole: evt.target.value})}
                                value={this.state.editMemberRole}
                                label='Member role'>
                            <Option value='' disabled>-- Select --</Option>
                            <Option value='owner'>Owner</Option>
                            <Option value='admin'>Administrator</Option>
                            <Option value='editor'>Editor</Option>
                        </Select>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton className="button--warning" action='remove' disabled={isLoadingDialog}>Remove
                            Access</DialogButton>
                        <div className="flex-spacer"/>
                        <DialogButton className="button--dismiss" action='dismiss'
                                      disabled={isLoadingDialog}>Close</DialogButton>
                        <DialogButton action='update' isDefault
                                      disabled={isLoadingDialog}>Confirm</DialogButton>
                    </DialogFooter>
                </Dialog>
                <PhoneCodeDialog
                    onClose={this._updateBankAccountOnPhoneConfirm.bind(this)}
                    open={isPhoneCodeDialogOpen}/>
                <LoadingDialog open={isLoadingDialog}/>
                <Snackbar leading open={this.state.snackOpen} message="Invitation has been sent!"
                          onClose={() => this.setState({snackOpen: false})}/>
            </div>
        )
    }

    _openEditMemberDialog = member => {
        this.setState({
            isEditMemberDialogOpen: true,
            editMemberEmail: member.email,
            editMemberId: member.id,
            editMemberName: member.name,
            editMemberRole: member.role
        })
    }

    _editMemberDialog = (action) => {
        const {editMemberId, editMemberRole} = this.state
        if (action === "update") {
            this.setState({isLoadingDialog: true})
            this.props.dispatch(updateOrganizationUserRole(editMemberId, editMemberRole, false, (res, catchErr) => {
                this.setState({isEditMemberDialogOpen: false, isLoadingDialog: false})

                if (res && res.error) alert("Error: " + res.error)
                if (catchErr) alert("Server Error! Please try again later.")
            }))
        } else if (action === "remove") {
            console.log("remove", editMemberId)
        } else {
        }
    }

    _sendUserInvitationDialog = action => {
        if (action === "accept") {
            this.setState({isLoadingDialog: true})
            this.props.dispatch(inviteMemberToOrganization(this.state.addMemberEmail, (res, catchErr) => {
                this.setState({isAddMemberDialogOpen: false, isLoadingDialog: false, addMemberEmail: "", snackOpen: true})
                if (res && res.error) alert("Error: " + res.error)
                if (catchErr) alert("Server Error! Please try again later.")
            }))
        } else {
            this.setState({isAddMemberDialogOpen: false, addMemberEmail: ""})
        }
    }

    _viewBillingHistory = () => {
        this.setState({isBillingHistoryDialogOpen: true});
    }

    _openIBANListDialog = () => {
        this.setState({isIBANDialogOpen: true, bankAccounts: null})
        this.props.dispatch(getOrganizationIBANList((list, err) => {
            this.setState({isIBANDialogOpen: !!list, bankAccounts: list})
        }))
    }

    _editBillingEmail = action => {
        if (action === "accept") {
            this.setState({isEditBillingEmailDialogOpen: false, isLoadingDialog: true})
            this.props.dispatch(updateUserBillingEmail(this.state.billingEmail, (_, err) => {
                this.setState({billingEmail: "", isLoadingDialog: false})
                alert("There was an error updating billing email. Please try again")
                this.props.dispatch(getCurrentOrganization())
            }))
        } else {
            this.setState({billingEmail: "", isEditBillingEmailDialogOpen: false})
        }
    }

    _onBankAccountSelected = (action) => {
        if (action === "accept") {
            this.setState({isIBANDialogOpen: false, isPhoneCodeDialogOpen: true});
        } else {
            this.setState({isIBANDialogOpen: false});
        }
    }

    _updateBankAccountOnPhoneConfirm = (action, code, verificationId) => {
        this.setState({isPhoneCodeDialogOpen: false})
        if (action === "accept") {
            const selectedIban = this.state.selectedBankAccount;
            this.props.dispatch(updateOrganizationIBAN(selectedIban, code, verificationId, (data, err) => {
                if (err) {
                    alert("There was an error sending request. Please try again later.")
                }
                this.props.dispatch(getCurrentOrganization())
            }))
        }
    }

    dialogHandleClose = (value) => {
        this.setState({open: false});
    }

    componentDidMount() {
        this.props.dispatch(getOrganizationUsers())
        this.props.dispatch(getBillingHistory())
    }
}

export default connect(mapStateToProps)(withTranslation("app")(AppSettings));
