import {UPDATE_PAGE} from '../actions/root.js';

const INITIAL_STATE = {
    page: '',
    offline: false,
    snackbarOpened: false,
};

const root = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_PAGE:
            return {
                ...state,
                page: action.page
            };
        default:
            return state;
    }
};

export default root;