import {GET_ORGANIZATION, GET_USERS_ACCESS, QUERY_ORGANIZATIONS} from "../actions/organizations";
import {createSelector} from "reselect";

const INITIAL_STATE = {
    active: null,
    ordered: [],
    limit: 25,
    loadMoreExhausted: false,
    updated: null,
    usersAccess: null,
    userAccessError: null,
};

const organizations = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ORGANIZATION:
            return {
                ...state,
                active: action.id,
                [action.id]: action.data,
            };
        case GET_USERS_ACCESS:
            return {
                ...state,
                usersAccess: action.data,
                userAccessError: action.userAccessError,
            };
        case QUERY_ORGANIZATIONS:
            return {
                ...state,
                ordered: action.replace ? [...(action.ordered || [])] : [...state.ordered, ...(action.ordered || [])],
                loadMoreExhausted: action.loadMoreExhausted,
                updated: action.updated,
                ...(action.ordered.reduce((previousValue, currentValue) => {
                    previousValue[currentValue.id] = currentValue
                    return previousValue
                }, {}))
            };
        default:
            return state;
    }
};

/*const _user = (state, action) => {
    switch (action.type) {
        case UPDATE_USER_2FA:
            return {
                ...state,
                ["status"]: {...state["status"], twoFactorAuthEnabled: action.twoFactorAuthEnabled}
            };
        default:
            return state
    }
};*/

export default organizations;

const _activeOrganizationSelector = state => state.organizations.active;
const _organizationsSelector = state => state.organizations;
export const activeOrganizationSelector = createSelector(
    _activeOrganizationSelector,
    _organizationsSelector,
    (id, data) => {
        return data[id] || {};
    }
);
