import React, {Component} from "react";
import {connect} from "react-redux";
import {isMobile} from "../components/utils";
import {withTranslation} from "react-i18next";
import {filterReceivedInvoicesByCompany} from "../actions/receivedInvoices";
import Dialog, {DialogButton, DialogContent, DialogFooter, DialogTitle} from "@material/react-dialog";
import Table from "../components/Table";
import {getPayoutRequests, updatePayoutRequest} from "../actions/payoutRequests";
import Select, {Option} from '@material/react-select';

const tableColumns = [
    {
        name: "createdAt",
        label: "Created",
        sortable: false,
        type: "timestmap"
    },
    {
        name: "payoutAmount.value",
        label: "Payout amount",
        sortable: false,
        type: "money"
    },
    {
        name: "receiver.name",
        label: "Receiver",
        sortable: false
    },
    {
        name: "status",
        label: "Status",
        sortable: false
    }
]

class AppPaymentRequests extends Component {
    state = {};

    render() {
        let {t, ordered = []} = this.props

        return (
            <div>
                <div className="mdc-layout-grid">
                    <div className="mdc-layout-grid__inner">
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                            <div className="table-header">
                                <div className="table-header__title">
                                    <h2 className="mdc-typography--headline5 title title--border table-header__title-text">
                                        Payout requests
                                    </h2>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table ordered={ordered} columns={tableColumns} actionDownload={true} disableSelection
                                       onAction={this._onTableAction.bind(this)}/>
                            </div>
                        </div>
                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 flex flex--justify-center">
                            <button type="button" onClick={this._loadMore.bind(this)}
                                    className="button button--unelevated"
                                    hidden={this.props.loadMoreExhausted}>{t('loadMore')}</button>
                        </div>
                    </div>
                </div>
                <Dialog onClose={(action) => this._onDialogAction(action)} open={this.state.isOpen}>
                    <DialogTitle>Update request status</DialogTitle>
                    <DialogContent>
                        <div>
                            <Select
                                className="mdc-select--default"
                                label='Status'
                                value={this.state.status}
                                onChange={(evt) => this.setState({status: evt.target.value})}>
                                <Option value='' disabled>-- Select --</Option>
                                <Option value='pending'>Pending</Option>
                                <Option value='completed'>Completed</Option>
                                <Option value='canceled'>Canceled</Option>
                            </Select>
                        </div>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton action='dismiss'>Cancel</DialogButton>
                        <DialogButton action='accept' isDefault>Confirm</DialogButton>
                    </DialogFooter>
                </Dialog>
            </div>
        )
    }

    _onTableAction(entry) {
        const {id, status} = entry
        this.setState({
            status: status,
            requestId: id,
            isOpen: true
        })
    }

    _onDialogAction(action) {
        if (action === 'accept') {
            console.log("updating status", this.state.requestId, this.state.status);
            this.props.dispatch(updatePayoutRequest(this.state.requestId, {status: this.state.status}))
        }
        this.setState({
            status: "",
            requestId: "",
            isOpen: false
        })
    }

    filterByCompany(e, id, name) {
        e.preventDefault();
        this.props.dispatch(filterReceivedInvoicesByCompany(id ? {id, name} : null))
    }

    _loadMore() {
        //this.props.dispatch(syncReceivedInvoices(true));
        this.props.dispatch(getPayoutRequests(true))
    }

    onRowClick(id) {
        if (isMobile()) {
            this.setState({redirect: id});
        }
    }

    componentDidMount() {
        this.props.dispatch(getPayoutRequests(false, true))
    }

    componentWillUnmount() {
        //const { firebase } = this.context.store
        // firebase.unsetListener({ collection: 'todos' }) // or object notation
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.permissionError && prevProps.permissionError !== this.props.permissionError) {
            //this.props.dispatch(updateUserOrganizationAccess(() => this.props.dispatch(getPayoutRequests(null))))
        }
    }
}

const mapStateToProps = state => {
    return {
        firebase: state.firebase,
        filterByCustomer: state.payoutRequests.filterByCustomer,
        loadMoreExhausted: state.payoutRequests.loadMoreExhausted,
        invoices: state.payoutRequests,
        ordered: state.payoutRequests.ordered,
        permissionError: state.payoutRequests.permissionError,
    };
};

export default connect(mapStateToProps)(withTranslation("app")(AppPaymentRequests));
