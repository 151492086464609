import firebase from "../firebase";

export const GET_INVOICE_ISSUERS = 'GET_INVOICE_ISSUERS'

export const getInvoiceIssuers = (loadMore = false, replace = false) => (dispatch, getState) => {
    const state = getState(), limit = state.invoiceIssuers.limit, ordered = state.invoiceIssuers.ordered,
        updated = (new Date()).getTime(),
        organizationId = state.organizations.active,
        doQuery = (query, replace = false) => {
            query.get()
                .then(snap => {
                    const ordered = [], dataMap = {};
                    snap.forEach(_doc => {
                        let data = _doc.data();

                        ordered.push({
                            ...data,
                            //_paymentStatus: _paymentStatus[data.paymentStatus],
                            id: _doc.id,
                            action: "view",
                            displayActions: true,
                            _doc
                        })
                        dataMap[_doc.id] = _doc
                    })

                    dispatch({
                        type: GET_INVOICE_ISSUERS, replace,
                        ordered, dataMap, loadMoreExhausted: (snap.empty || snap.size < limit), updated
                    })
                }).catch(err => console.error(err))
        }

    if (ordered.length > 0 && !loadMore) return

    let query = firebase.firestore().collection("organizations").doc(organizationId)
        .collection("invoice-issuers")
        .orderBy("name", "asc")
        .limit(limit)
    //.where("organizationId", "==", organizationId)

    //if (filterByCustomer) query = query.where("customer.id", "==", filterByCustomer.id)
    if (loadMore && ordered.length > 0) query = query.startAfter(ordered[ordered.length - 1]._doc)

    doQuery(query, replace)
}
