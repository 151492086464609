import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    active: true
};

export const slice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setActive: (state, action) => ({active: action.payload}),
    }
});

// ACTIONS

const {setActive} = slice.actions;

export const setGlobalLoader = (isLoading = false) => {
    return setActive(isLoading)
}

// SELECTORS

export const isGlobalLoaderActive = state => state.loader.active;

export default slice.reducer;
