import firebase, {firestore, functions} from "../firebase";
import {API_URL} from "../config";
import {activeOrganizationSelector} from "../reducers/organizations";
import payoutRequests from "../reducers/payoutRequests";

export const GET_PAYOUT_REQUESTS = 'GET_PAYOUT_REQUESTS'
export const GET_AUTO_PAYOUT_SETTINGS = 'GET_AUTO_PAYOUT_SETTINGS'
/*export const FILTER_ISSUED_INVOICES_BY_COMPANY = 'FILTER_ISSUED_INVOICES_BY_COMPANY'
export const GET_ISSUED_INVOICES_PERMISSION_ERROR = 'GET_ISSUED_INVOICES_PERMISSION_ERROR'*/

export const getPayoutRequests = (loadMore = false, refresh = false) => (dispatch, getState) => {
    const state = getState(), limit = state.payoutRequests.limit, ordered = state.payoutRequests.ordered,
        filterByCustomer = state.payoutRequests.filterByCustomer, updated = (new Date()).getTime(),
        {accessByVatNum} = activeOrganizationSelector(state),
        doQuery = (query, replace = false) => {
            query.get()
                .then(snap => {
                    const ordered = [], dataMap = {};
                    snap.forEach(_doc => {
                        let data = _doc.data(), selectable = true

                        ordered.push({
                            ...data,
                            selectable,
                            id: _doc.id,
                            displayActions: false,
                            _doc
                        })
                        dataMap[_doc.id] = _doc
                    })
                    dispatch({
                        type: GET_PAYOUT_REQUESTS, replace,
                        ordered, dataMap, loadMoreExhausted: (snap.empty || snap.size < limit), updated
                    })
                })
                .catch(err => {
                    console.error(err)
                    console.log(err.code)
                    if (err.code === "permission-denied") {
                       /* dispatch({
                            type: GET_ISSUED_INVOICES_PERMISSION_ERROR
                        })*/
                    }
                })
        }

    if (ordered.length > 0 && !loadMore && !refresh) return

    let query = firestore.collection("financing-requests")
        .orderBy("createdAt", "desc")
        .limit(limit)

    //if (filterByCustomer) query = query.where("customer.id", "==", filterByCustomer.id)
    if (loadMore && ordered.length > 0) query = query.startAfter(ordered[ordered.length - 1]._doc)

    doQuery(query, refresh)
}

export const updatePayoutRequest = (id, updates) => (dispatch, getState) => {
    firestore.collection("financing-requests").doc(id).update(updates).then(() => {
        dispatch(getPayoutRequests(false, true))
    })
}

export const getAutoPayoutSettings = () => (dispatch, getState) => {
    const state = getState(), organizationId = state.organizations.active
    firestore.collection("auto-payout").doc(organizationId).get().then(doc => {
        dispatch({
            type: GET_AUTO_PAYOUT_SETTINGS,
            autoPayoutSettings: doc.data()
        })
    })
}

export const changeAutoPayoutSettings = (autoPayoutSelection, autoPayoutCustom, cb) => (dispatch, getState) => {
    const state = getState(), organizationId = state.organizations.active,
        changeAutoPayoutSettings = functions.httpsCallable("changeAutoPayoutSettings")
    autoPayoutCustom = typeof autoPayoutCustom === "string" ? parseInt(autoPayoutCustom) : autoPayoutCustom
    changeAutoPayoutSettings({
        autoPayoutSelection, autoPayoutCustom, organizationId
    }).then(res => {
        if (cb) cb(res.data)
        dispatch(getAutoPayoutSettings())
    }).catch(err => {
        console.error(err)
        if (cb) cb(null, err)
    })
}
