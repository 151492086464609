import React, {Component} from "react";
import {connect} from "react-redux";
import {requestAccountDeletion, updateUserNotificationSubscriptions} from "../actions/user";
import Switch from '@material/react-switch';
import {withTranslation} from "react-i18next";
import Select, {Option} from "@material/react-select";
import Dialog, {DialogButton, DialogContent, DialogFooter, DialogTitle} from "@material/react-dialog";
import {auth} from "../firebase";
import LoadingDialog from "../components/LoadingDialog";

const mapStateToProps = state => {
    return {
        user: state.firebase.auth,
        _profile: state.firebase.profile,
        _activeTab: state.tabs.tab,
    };
};

class AppAccount extends Component {
    state = {
        open: false,
        verifiedId: null,
        newsletterSubscription: "NONE",
        news: false,
        updates: false,
        isChangePasswordDialogOpen: false,
        isDeleteAccountDialogOpen: false,
        isLoadingDialogOpen: false,
    };

    render() {
        const {user, _profile, t} = this.props, {
            isChangePasswordDialogOpen,
            isLoadingDialogOpen,
            isDeleteAccountDialogOpen
        } = this.state

        return (
            <div className="mdc-layout-grid">
                <div className="mdc-layout-grid__inner">
                    <div
                        className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet">
                        <h2 className="mdc-typography--headline5 title title--border">{t('personalDataAndSecurityTitle')}</h2>
                        <i className="title-spacer"/>
                        <div className="list list--vertical list--ordinal-background">
                            <div className="list-item">
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item">User ID</span>
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.uid}</b></span>
                            </div>
                            <div className="list-item">
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item">Name and surname</span>
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{_profile.firstName} {_profile.lastName}</b></span>
                            </div>
                            <div className="list-item">
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item">{t('address')}</span>
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item"><b>
                                    {_profile.address.streetName} {_profile.address.streetNumber},&nbsp;
                                    {_profile.address.city}, {_profile.address.country}
                                    </b></span>
                            </div>
                            <div className="list-item">
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item">{t('email')}</span>
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{_profile.email}</b></span>
                            </div>
                            <div className="list-item">
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item">{t('phone')}</span>
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{_profile.phone}</b></span>

                            </div>
                            <div className="list-item">
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item">{t('defaultLanguage')}</span>
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item"><b>English</b></span>
                            </div>
                            <div className="list-item">
                                <span
                                    className="mdc-typography--body1 list-item__text custom-account-list-item">{t('changePassword')}</span>
                                <div
                                    className="mdc-typography--body1 list-item__other custom-account-list-item">
                                    <button onClick={() => this.setState({isChangePasswordDialogOpen: true})}
                                            className="button button--unelevated button--primary">{t('changePassword')}</button>
                                </div>
                            </div>
                            <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('deleteAccount')}</span>
                                <div
                                    className="mdc-typography--body1 list-item__other custom-account-list-item">
                                    <button onClick={() => this.setState({isDeleteAccountDialogOpen: true})}
                                            className="button button--unelevated button--primary">{t('delete')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                        <h2 className="mdc-typography--headline5 title title--border">{t('notificationsTitle')}</h2>
                        <i className="title-spacer"/>
                        <div className="list list--vertical list--ordinal-background">
                            {/*<div className="list-item">
                                        <span className="mdc-typography--body1 list-item__text custom-account-list-item"><b>Account summary</b></span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item custom-account-list-item--adjust-end">
                                            <div className={user.settings.notifications && user.settings.notifications.accountSummary ? "mdc-switch mdc-switch--checked" : "mdc-switch"}>
                                                <div className="mdc-switch__track"/>
                                                <div className="mdc-switch__thumb-underlay">
                                                    <div className="mdc-switch__thumb">
                                                        <input ref="summary" type="checkbox" className="mdc-switch__native-control"
                                                               role="switch" defaultChecked={user.settings.notifications && user.settings.notifications.accountSummary === true}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}
                            <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('accountSummaryNotifications')}</b></span>
                                <div
                                    className="mdc-typography--body1 list-item__other custom-account-list-item custom-account-list-item--adjust-end">
                                    <div className="form-field">
                                        <Select
                                            className="mdc-select--default mdc-select--default-color-white mdc-select--default-outlined-no-border"
                                            enhanced outlined
                                            //disabled={!!this.state.newsletterSubscription}
                                            value={this.state.newsletterSubscription}
                                            onEnhancedChange={this.onNewsletterSubscriptionChange}>
                                            <Option value='NONE'>{t("NONE")}</Option>
                                            <Option value='DAILY'>{t("DAILY")}</Option>
                                            <Option value='WEEKLY'>{t("WEEKLY")}</Option>
                                            <Option value='MONTHLY'>{t("MONTHLY")}</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('newsNotifications')}</b></span>
                                <div
                                    className="mdc-typography--body1 list-item__other custom-account-list-item custom-account-list-item--adjust-end">
                                    <Switch
                                        checked={this.state.news}
                                        onChange={e => this.onToggle("news", e.target.checked)}/>
                                </div>
                            </div>
                            <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('updatesNotifications')}</b></span>
                                <div
                                    className="mdc-typography--body1 list-item__other custom-account-list-item custom-account-list-item--adjust-end">
                                    <Switch
                                        checked={this.state.updates}
                                        onChange={e => this.onToggle("updates", e.target.checked)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    onClose={this.changePassword}
                    open={isChangePasswordDialogOpen}>
                    <DialogTitle>Change Password</DialogTitle>
                    <DialogContent>
                        <p>
                            We will send you an email link with instructions on how to change your password. Do you wish
                            to continue?
                        </p>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton className="button--dismiss" action='dismiss' isDefault>Close</DialogButton>
                        <div className="flex-spacer"/>
                        <DialogButton action='accept'>Send Password Reset Email</DialogButton>
                    </DialogFooter>
                </Dialog>
                <Dialog
                    onClose={this.deleteAccount}
                    open={isDeleteAccountDialogOpen}>
                    <DialogTitle>Delete Account</DialogTitle>
                    <DialogContent>
                        <p>
                            Are you sure you wish to continue? After this you wont be able to reverse this action.
                        </p>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton className="button--dismiss" action='dismiss' isDefault>Cancel</DialogButton>
                        <div className="flex-spacer"/>
                        <DialogButton className="button--warning" action='accept'>Delete Account</DialogButton>
                    </DialogFooter>
                </Dialog>
                <LoadingDialog open={isLoadingDialogOpen}/>
            </div>
        )
    }

    deleteAccount = action => {
        if (action === "accept") {
            this.setState({isDeleteAccountDialogOpen: false, isLoadingDialogOpen: true})
            this.props.dispatch(requestAccountDeletion((data, err) => {
                this.setState({isLoadingDialogOpen: false})
                if (err) {
                    alert("There was an unexpected error. Please try again")
                } else {
                    auth.signOut()
                }
            }));
        } else {
            this.setState({isDeleteAccountDialogOpen: false})
        }
    }

    changePassword = action => {
        if (action === "accept") {
            this.setState({isChangePasswordDialogOpen: false, isLoadingDialogOpen: true})
            auth.sendPasswordResetEmail(this.props.user.email)
                .then(() => {
                    this.setState({isLoadingDialogOpen: false})
                })
                .catch(err => {
                    console.error(err)
                    this.setState({isLoadingDialogOpen: false})
                    alert("There was an error sending password reset email. Please try again")
                })

        } else {
            this.setState({isChangePasswordDialogOpen: false})
        }
    }

    dialogHandleClose = (value) => {
        this.setState({open: false});
    };

    /*enableTwoFactorAuth = () => {
        this.props.dispatch(requestSmsToken('TWO_FACTOR_CHANGE', (event) => {
            this.setState({verifiedId: event.Id, open: true});
        }));
    }

    disableTwoFactorAuth = () => {
        this.props.dispatch(requestSmsToken('TWO_FACTOR_CHANGE', (event) => {
            this.setState({verifiedId: event.Id, open: true});
        }));
    }*/

    onNewsletterSubscriptionChange = (index, item) => {
        const value = item.getAttribute('data-value');
        this.setState({newsletterSubscription: value})
        this.props.dispatch(updateUserNotificationSubscriptions({newsletterSubscription: value}, data => {
            if (data && typeof data === "object" && data.success === false) {
                alert("There was an error updating notifications settings")
            }
        }));
    }

    onToggle = (type, checked) => {
        this.setState({[type]: checked})
        this.props.dispatch(updateUserNotificationSubscriptions({[type]: checked}, data => {
            if (data && typeof data === "object" && data.success === false) {
                alert("There was an error updating notifications settings")
            }
        }));
    }

    componentDidMount() {
        if (this.props._profile && this.props._profile.subscriptions) {
            this.setState({...this.props._profile.subscriptions})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps._profile !== this.props._profile && this.props._profile.subscriptions) {
            this.setState({...this.props._profile.subscriptions})
        }
    }

}

export default connect(mapStateToProps)(withTranslation("app")(AppAccount));
