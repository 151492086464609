import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    pendingInvitation: ""
};

export const slice = createSlice({
    name: 'invitation',
    initialState,
    reducers: {
        setPendingInvitation: (state, action) => ({pendingInvitation: action.payload}),
    }
});

// ACTIONS

//const {setPendingInvitation} = slice.actions;

export const setPendingInvitation = (invitationId = "") => {
    return slice.actions.setPendingInvitation(invitationId)
}

// SELECTORS

export const pendingInvitation = state => state.invitation.pendingInvitation;

export default slice.reducer;
