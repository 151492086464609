import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {activeOrganizationSelector} from "../reducers/organizations";
import {connect} from "react-redux";
import LoadingDialog from "../components/LoadingDialog";
import firebase, {functions} from "../firebase";
import Dialog, {DialogButton, DialogContent, DialogFooter, DialogTitle} from "@material/react-dialog";

const mapStateToProps = state => {
    return {
        _organization: activeOrganizationSelector(state),
        user: state.firebase.auth,
        organizationId: state.firebase.profile.organizationId,
        usersAccess: state.organizations.usersAccess,
        _activeTab: state.tabs.tab,
    };
};

const DIALOG_ACCEPT = "DIALOG_ACCEPT",
    DIALOG_ALREADY_ACCESS = "DIALOG_ALREADY_ACCESS",
    DIALOG_NO_ACCEPT_WITH_ACCESS = "DIALOG_NO_ACCEPT_WITH_ACCESS",
    DIALOG_PROMPT = "DIALOG_PROMPT"

class AcceptInvitation extends Component {
    state = {
        isLoadingDialog: false,
        isDialogOpen: false,
        dialogType: null,
        invitation: {}
    };

    render() {
        const {isLoadingDialog, isDialogOpen, dialogType, invitation, dialogTitle, dialogTextContent} = this.state;
        // Različni prikazi:
        // 0. Napaka - alert in redirect
        // 1. Up. že ima dostop - redirect
        // 3. Up. nima dostopa do nobene organizacije - sprejmi
        // 4. Up. že ima dostop do neke druge organizacije - obvestilo, če sprejme, dostopa do stare ne bo ohranil
        //if (redirect) return <Redirect to="/sent-confirmation"/>;
        return (
            <div>
                <Dialog
                    onClose={this._onDialogAction.bind(this)}
                    open={isDialogOpen && dialogType === DIALOG_NO_ACCEPT_WITH_ACCESS}>
                    <DialogTitle>Accept Invite</DialogTitle>
                    <DialogContent>
                        <p>You can not accept access to <b>{invitation.organizationName}</b> as you already have access to another organization.</p>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton action='switch'>Switch account</DialogButton>
                        <div className="flex-spacer"/>
                        <DialogButton className="button--dismiss" action='dismiss'>Close</DialogButton>
                    </DialogFooter>
                </Dialog>
                <Dialog
                    onClose={this._onDialogAction.bind(this)}
                    open={isDialogOpen && dialogType === DIALOG_ACCEPT}>
                    <DialogTitle>Accept Invite</DialogTitle>
                    <DialogContent>
                        <p>You have been invited to <b>{invitation.organizationName}</b>. Do you wish to accept the
                            invitation?</p>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton className="button--dismiss" action='dismiss'>Close</DialogButton>
                        <DialogButton action='accept' isDefault>Accept</DialogButton>
                    </DialogFooter>
                </Dialog>
                <Dialog
                    onClose={this._onDialogAction.bind(this)}
                    open={isDialogOpen && dialogType === DIALOG_ALREADY_ACCESS}>
                    <DialogTitle>Accept Invite</DialogTitle>
                    <DialogContent>
                        <p>You already have access to <b>{invitation.organizationName}</b>.</p>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton action='switch'>Switch account</DialogButton>
                        <div className="flex-spacer"/>
                        <DialogButton className="button--dismiss" action='dismiss' isDefault>Close</DialogButton>
                    </DialogFooter>
                </Dialog>
                <Dialog
                    onClose={this._onDialogAction.bind(this)}
                    open={isDialogOpen && dialogType === DIALOG_PROMPT}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <p>{dialogTextContent}</p>
                    </DialogContent>
                    <DialogFooter>
                        <DialogButton className="button--dismiss" action='dismiss'>Close</DialogButton>
                    </DialogFooter>
                </Dialog>
                <LoadingDialog open={isLoadingDialog}/>
            </div>
        )
    }

    _onDialogAction(action) {
        // accept or redirect
        if (action === "accept") {
            this.setState({isLoadingDialog: true})
            //acceptUserOrganizationInvitation
            const acceptUserOrganizationInvitation = functions.httpsCallable("acceptUserOrganizationInvitation")
            acceptUserOrganizationInvitation({invitationId: this.props.id}).then(res => {
                if (res && res.data && res.data.success) {
                    if(res.data.success) {
                        window.location.href = "/u/settings"
                        return
                    } else if(res.data.error) {
                        this.setState({
                            isLoadingDialog: false,
                            isDialogOpen: true,
                            dialogType: DIALOG_PROMPT,
                            dialogTitle: "Error",
                            dialogTextContent: res.data.error
                        })
                        return
                    }
                }
                this.setState({
                    isLoadingDialog: false,
                    isDialogOpen: true,
                    dialogType: DIALOG_PROMPT,
                    dialogTitle: "Error",
                    dialogTextContent: "There was some error processing your request. Please try again later."
                })
            }).catch(err => {
                console.error(err)
                this.setState({
                    isLoadingDialog: false,
                    isDialogOpen: true,
                    dialogType: DIALOG_PROMPT,
                    dialogTitle: "Error",
                    dialogTextContent: "There was some error processing your request. Please try again later."
                })
            })
        } else if(action === "switch") {
            firebase.auth().signOut().finally(() => window.location.reload())
        } else {
            // redirect
            //window.location.href = "/u/settings"
        }
    }

    _loadDialog(id) {
        this.setState({isLoadingDialog: true})
        const getUserOrganizationInvitation = functions.httpsCallable("getUserOrganizationInvitation")
        getUserOrganizationInvitation({id}).then(res => {
            if (res && res.data) {
                const {success, error, organizationId} = res.data
                if(success) {
                    if (this.props.organizationId) {
                        if(this.props.organizationId === organizationId) {
                            this.setState({
                                isLoadingDialog: false,
                                isDialogOpen: true,
                                dialogType: DIALOG_ALREADY_ACCESS,
                                invitation: res.data
                            })
                        } else {
                            this.setState({
                                isLoadingDialog: false,
                                isDialogOpen: true,
                                dialogType: DIALOG_NO_ACCEPT_WITH_ACCESS,
                                invitation: res.data
                            })
                        }
                    } else {
                        this.setState({
                            isLoadingDialog: false,
                            isDialogOpen: true,
                            dialogType: DIALOG_ACCEPT,
                            invitation: res.data
                        })
                    }
                    return
                } else if(error) {
                    this.setState({
                        isLoadingDialog: false,
                        isDialogOpen: true,
                        dialogType: DIALOG_PROMPT,
                        dialogTitle: "Error",
                        dialogTextContent: error
                    })
                    return
                }
            }
            this.setState({
                isLoadingDialog: false,
                isDialogOpen: true,
                dialogType: DIALOG_PROMPT,
                dialogTitle: "Error",
                dialogTextContent: "There was some error processing your request. Please try again later."
            })
        }).catch(err => {
            console.error(err)
            this.setState({
                isLoadingDialog: false,
                isDialogOpen: true,
                dialogType: DIALOG_PROMPT,
                dialogTitle: "Error",
                dialogTextContent: "There was some error processing your request. Please try again later."
            })
        })
    }

    componentDidMount() {
        this._loadDialog(this.props.id)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.id !== prevProps.id) {
            // get
            this._loadDialog(this.props.id)
        }
    }
}

export default connect(mapStateToProps)(withTranslation("app")(AcceptInvitation));
