import {SET_ACTIVE_TAB} from '../actions/tabs';

const INITIAL_STATE = {
    tab: -1
};

const tabs = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ACTIVE_TAB:
            return {
                ...state,
                tab: action.tab
            };
        default:
            return state;
    }
};
export default tabs;