import {
    GET_BILLING_HISTORY
} from '../actions/billing';

const INITIAL_STATE = {
    ordered: [],
    limit: 24,
    updated: null,
    error: false
};

const payoutRequests = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_BILLING_HISTORY:
            return {
                ...state,
                ...action.dataMap,
                ordered: action.ordered || [],
                updated: action.updated,
                error: false
            };
        /*case GET_ISSUED_INVOICES_PERMISSION_ERROR:
            return {
                ...state,
                permissionError: true
            };
        case FILTER_ISSUED_INVOICES_BY_COMPANY:
            return {
                ...state,
                filterByCustomer: action.customer,
                ordered: [],
                loadMoreExhausted: false,
                permissionError: false
            };*/
        default:
            return state;
    }
};

export default payoutRequests;
